import {
  getPartnerSummaryStatsAPI,
  parsePartnerSummaryStats
} from 'services/home.service';
import * as HttpService from 'services/http.service';
import { GET_PARTNER_USER_PROFILE_DATA } from 'services/url.service';

export const getUserProfileData = () =>
  HttpService.getWithAuth(GET_PARTNER_USER_PROFILE_DATA());

export const getUserProfileDataAPI = async () => {
  const { entity: details, message, status } = await getUserProfileData();
  if (status) {
    return details;
  }
  return Promise.reject(new Error(message));
};

export const getPartnerSummaryStats = async () => {
  const { entity, status, message } = await getPartnerSummaryStatsAPI();
  if (status) {
    const summaryStats = parsePartnerSummaryStats({ entity });
    return summaryStats;
  }
  return Promise.reject(new Error(message));
};

export const getProfileData = ({
  address,
  coreCategories,
  emailId,
  mobileNumber,
  pocName
}) => ({
  address,
  emailId,
  eventTypes: coreCategories
    .map(({ name }) => name)
    .filter((value) => value)
    .join(', '),
  mobileNumber,
  pocName
});
