import _ from 'lodash';
import Image from 'next/image';
import { useEffect } from 'react';
import { ClearRefinements, useStats } from 'react-instantsearch';

import { Button, Text } from '@/components/atomic/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';

const filterEmptyClassnamesAndJoin = (classNameStringList) =>
  classNameStringList.filter((x) => x).join(' ');

const SearchSortFilter = ({
  attribute,
  buttonName,
  Component,
  componentProps,
  countOfSelectedRefinement,
  isOpen,
  onClearAllRefinement,
  setOpenFilter,
  shouldShowClearButton = true
}) => {
  const { nbHits: resultsCount } = useStats();
  return (
    <div
      className={filterEmptyClassnamesAndJoin([
        'w-full md:w-72 max-h-96 overflow-auto border shadow-sm border-platinum rounded-t-3xl md:rounded-none bg-white fixed md:absolute',
        'z-10 md:z-2 md:mt-14 searchkit-mobile-modal-popup',
        `${isOpen ? '' : 'hidden'}`
      ])}
    >
      <div className='flex md:hidden mx-auto bg-brand-gradient h-1 w-10 rounded-full mt-1' />
      <div className='flex md:hidden justify-between text-base font-medium px-5 pt-4'>
        <Text {...{ content: buttonName, className: 'self-center' }} />
        <div>
          <Image
            {...{
              alt: 'close-icon',
              height: 0,
              onClick: () => setOpenFilter(null),
              src: `${staticMediaStoreBaseURL}/icons/close-with-border-gray.svg`,
              style: { width: 16, height: 16 },
              width: 0
            }}
          />
        </div>
      </div>
      <div className='px-4 py-4'>
        <Component {...componentProps} />
      </div>

      <div className='flex md:hidden justify-between text-xs font-medium border-t border-platinum px-5 py-3 bg-base-white'>
        {shouldShowClearButton && (
          <div
            className='text-brand self-center'
            onClick={onClearAllRefinement}
          >
            <ClearRefinements
              {...{
                includedAttributes: attribute,
                classNames: {
                  button: 'searchkit-clear-button-mobile',
                  root: 'searchkit-clear-all-button-container'
                },
                translations: {
                  resetButtonText: 'Clear'
                }
              }}
            />
          </div>
        )}
        <Button
          {...{
            children: `Show ${resultsCount} Results`,
            className: 'text-white bg-brand-gradient px-4 py-2 rounded-md',
            disabled: _.isUndefined(countOfSelectedRefinement),
            onClick: () => setOpenFilter(null),
            width: ''
          }}
        />
      </div>
    </div>
  );
};

const DynamicFilterMobile = ({
  filterProps: {
    attribute,
    buttonName,
    Component,
    componentProps,
    count: countOfSelectedRefinement,
    icon,
    isOpen,
    onClearAllRefinement = () => {},
    onToggle,
    selectedIcon,
    setOpenFilter,
    showCount = true,
    shouldShowClearButton
  }
}) => {
  useEffect(() => {
    const addOrRemove = isOpen ? 'add' : 'remove';
    document.body.classList[`${addOrRemove}`]('overflow-hidden');
  }, [isOpen]);

  const isFilterAppliedAndDrawerClosed =
    countOfSelectedRefinement > 0 && !isOpen;

  const displayCountOfRefinement = showCount && countOfSelectedRefinement;
  return (
    <div className='flex relative'>
      <div
        onClick={() => onToggle(attribute)}
        className={filterEmptyClassnamesAndJoin([
          'flex gap-2 px-3 py-1 rounded-md border',
          `${isOpen ? 'border-brand' : ''}`,
          `${isFilterAppliedAndDrawerClosed ? 'bg-brand-gradient' : ''}`
        ])}
      >
        <div className='w-4 h-4 flex self-center'>
          <Image
            alt='event-type'
            height={0}
            src={`${staticMediaStoreBaseURL}/icons/${
              isFilterAppliedAndDrawerClosed ? selectedIcon : icon
            }`}
            style={{ width: 16, height: 16 }}
            width={0}
          />
        </div>
        <div className='flex gap-2'>
          <Button
            {...{
              children: buttonName,
              className: filterEmptyClassnamesAndJoin([
                'font-medium text-sm text-nowrap',
                `${isOpen ? 'text-nero' : ''}`,
                `${
                  isFilterAppliedAndDrawerClosed
                    ? 'text-white'
                    : 'text-dim-gray'
                }`
              ])
            }}
          />
          {displayCountOfRefinement && (
            <Text
              {...{
                className: `text-[10px] self-center font-medium bg-white text-brand rounded-full searchkit-mobile-count w-4 h-4 flex justify-center`,
                content: countOfSelectedRefinement
              }}
            />
          )}
        </div>
        <div className='w-4 h-4 flex self-center items-center'>
          <Image
            alt='down angle'
            height={0}
            src={`${staticMediaStoreBaseURL}/icons/${
              isFilterAppliedAndDrawerClosed
                ? 'down-angle-white.svg'
                : 'down-angle.svg'
            }`}
            style={{ width: 12, height: 12 }}
            width={0}
          />
        </div>
      </div>
      {isOpen && (
        <div className='searchkit-sort-overlayer overlayer left-0 top-0 z-20' />
      )}
      <SearchSortFilter
        {...{
          attribute,
          buttonName,
          Component,
          componentProps,
          countOfSelectedRefinement,
          isOpen,
          onClearAllRefinement,
          setOpenFilter,
          shouldShowClearButton
        }}
      />
    </div>
  );
};

export default DynamicFilterMobile;
