import { useRouter } from 'next/router';

import { Button } from '@/components/atomic/atoms';
import {
  HomePageHeaderInfoMobile,
  NavigationCard,
  StatsBox
} from '@/components/atomic/molecules';
import { staticMediaStoreBaseURL } from '@/config/common';
import { PAGE_NAME, getPageURL } from '@/services/partnerPortal.service';

const HomePageMobileOrganism = ({ partnerSummaryStats, partnerUser }) => {
  const { menuItemList, partnerTier, statsArray } = partnerSummaryStats;
  const { emailId, legalNameOfPartnerOrg, mobileNumber } = partnerUser;
  const router = useRouter();
  return (
    <div>
      <HomePageHeaderInfoMobile
        {...{ emailId, legalNameOfPartnerOrg, mobileNumber, partnerTier }}
      />
      <div className='flex gap-4 md:gap-8 md:w-47.5 mx-auto md:mt-4 px-4 md:px-0 -mt-13'>
        {statsArray.map(
          (
            { count, countLabel, heading, iconUrl, value, valueLabel },
            index
          ) => (
            <StatsBox
              key={index}
              {...{
                count,
                countLabel,
                heading,
                iconUrl,
                value,
                valueLabel
              }}
            />
          )
        )}
      </div>
      <div className='flex flex-col gap-5 mx-4 md:mx-auto bg-white md:w-47.5 mt-5 rounded-xl min-h-590 mb-10'>
        {menuItemList.map(
          (
            { className, count, href, icon, label, shortDescription },
            index
          ) => (
            <NavigationCard
              key={index}
              {...{ className, count, href, icon, label, shortDescription }}
            />
          )
        )}
        <Button
          {...{
            children: 'Contact Us',
            className:
              'text-brand border border-neutral shadow font-medium rounded-md py-3',
            iconPosition: 'Left',
            iconUrl: `${staticMediaStoreBaseURL}/icons/call.svg`,
            iconWidth: 16,
            iconHeight: 16,
            onClick: () =>
              router.push(getPageURL({ pageName: PAGE_NAME.CONTACT_US.label }))
          }}
        />
      </div>
    </div>
  );
};

export default HomePageMobileOrganism;
