import Image from 'next/image';
import Link from 'next/link';

import { Text } from '@/components/atomic/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';

const RightAngleImage = () => (
  <div className='px-1 font-base text-dim-gray flex justify-items-center me-1.5'>
    <Image
      {...{
        alt: 'right arrow',
        height: 0,
        src: `${staticMediaStoreBaseURL}/icons/right-angle.svg`,
        style: {
          width: 14,
          height: 14
        },
        width: 0
      }}
    />
  </div>
);

const BreadCrumbName = ({ isLastBreadCrumb, name }) => (
  <Text
    {...{
      className: `me-1.5 font-base h-full text-base font-medium font-Montserrat hover:text-coral ${
        isLastBreadCrumb ? 'text-nero' : 'text-dim-gray'
      }`,
      content: name,
      HtmlTag: 'span'
    }}
  />
);

const Breadcrumbs = ({ breadCrumbs }) => (
  <div className='w-full flex gap-1 ms-1'>
    {breadCrumbs.map(({ name, link }, index) => {
      const isLastBreadCrumb = index === breadCrumbs.length - 1;

      return (
        <div
          className='flex flex-row justify-center items-center'
          key={index}
        >
          {isLastBreadCrumb ? (
            <BreadCrumbName {...{ isLastBreadCrumb, name }} />
          ) : (
            <>
              <Link href={link}>
                <BreadCrumbName {...{ isLastBreadCrumb, name }} />
              </Link>
              <RightAngleImage />
            </>
          )}
        </div>
      );
    })}
  </div>
);

export default Breadcrumbs;
