import { Fragment } from 'react';

import {
  ContactForm,
  HaflaContactDetails
} from '@/components/atomic/molecules';

const ContactUsMobileOrganism = ({ legalNameOfPartnerOrg, setShowToast }) => (
  <Fragment>
    <ContactForm
      {...{
        legalNameOfPartnerOrg,
        setShowToast
      }}
    />
    <HaflaContactDetails />
  </Fragment>
);

export default ContactUsMobileOrganism;
