import { useForm } from 'react-hook-form';

import { Text, TextArea, TextKeyValuePair } from '@/components/atomic/atoms';
import { BidCTA, BidDeliveryInfo } from '@/components/atomic/molecules';
import { BID_STATUS, BID_SUBMISSION_STATUS, DELIVERY_MODE } from '@/config/bid';
import {
  parseFormatPriceNumberValueFromAPI,
  parsePriceValueForAPI
} from '@/lib/numberStringUtils';
import {
  LOCAL_STORAGE_KEYS,
  setLocalStorageValue
} from '@/services/localStorage.service';

const GrandTotalExcludingVAT = ({ bidStatusBasedClass, offerAmount }) => (
  <div className={'flex gap-2 flex-row'}>
    <div
      className={`text-black text-sm md:text-base font-medium ${bidStatusBasedClass}`}
    >
      Grand Total:
    </div>
    <div
      className={`text-black text-sm md:text-base font-medium ${bidStatusBasedClass}`}
    >
      <Text
        {...{
          HtmlTag: 'p',
          content: `${parseFormatPriceNumberValueFromAPI(
            offerAmount
          )} (Excl. VAT)`
        }}
      />
    </div>
  </div>
);

const BidSummaryOrganism = ({
  bidId,
  deliveryMode,
  disableSubmitCTA,
  getBidDetail,
  offerAmount,
  setDeliveryChargeOffered,
  setDeliveryMode,
  setShowRequestCallbackModal,
  setShowToast,
  status,
  submissionStatus,
  submitBid,
  summary
}) => {
  const { deliveryCharge, partnerNotes, plannerName } = summary;
  const disableFields =
    status === BID_STATUS.EXPIRED.value ||
    submissionStatus === BID_SUBMISSION_STATUS.SUBMITTED.value;

  const { register, handleSubmit } = useForm({
    defaultValues: {
      deliveryMode,
      deliveryCharge: parseFormatPriceNumberValueFromAPI(deliveryCharge),
      partnerNotes
    }
  });
  const onSubmit = async ({
    deliveryCharge: deliveryChargeOfferedValue,
    partnerNotes: partnerNotesValue
  }) => {
    const payloadData = {
      partnerNotes: partnerNotesValue,
      deliveryCharge:
        deliveryMode === DELIVERY_MODE.SELF_DELIVERY.value
          ? parsePriceValueForAPI(deliveryChargeOfferedValue)
          : null,
      deliveryMode
    };
    await submitBid({
      bidId,
      data: payloadData
    })
      .then(async ({ status: apiStatus, message }) => {
        if (apiStatus) {
          await getBidDetail();
        }
        setLocalStorageValue({
          key: LOCAL_STORAGE_KEYS.IS_BID_LIST_INDEX_UPDATED,
          value: true
        });
        setShowToast({ show: true, status: apiStatus, message });
      })
      .catch(({ response }) => {
        setShowToast({
          show: true,
          status: false,
          message: response?.data?.message || 'Failed to submit'
        });
      });
  };

  const bidStatusBasedClass =
    status === BID_STATUS.EXPIRED.value ? 'expired-bid' : '';
  return (
    <div className={`${bidStatusBasedClass} bid-summary-organism `}>
      <Text
        {...{
          className: `text-base md:text-xl font-medium mx-6 md:mx-0 mb-3 ${bidStatusBasedClass}`,
          content: 'Summary'
        }}
      />
      <div className='flex flex-col gap-4 shadow-card border border-neutral px-3 md:px-8 pt-2 pb-5 mx-5 md:mx-0 rounded-xl'>
        {deliveryMode !== DELIVERY_MODE.NOT_APPLICABLE.value && (
          <BidDeliveryInfo
            {...{
              deliveryCharge,
              deliveryMode,
              disableFields,
              register,
              setDeliveryChargeOffered,
              setDeliveryMode,
              status
            }}
          />
        )}
        <div className='flex flex-col gap-2'>
          <Text
            {...{
              content: 'Comments:',
              className: `text-sm md:text-base font-medium ${bidStatusBasedClass}`
            }}
          />
          <TextArea
            {...{
              defaultValue: partnerNotes,
              disabled: disableFields,
              register: register('partnerNotes')
            }}
          />
        </div>
        <div className='flex flex-col gap-2 relative'>
          {plannerName && (
            <TextKeyValuePair
              {...{
                className: 'flex-row',
                label: 'Planner Name: ',
                labelClass: `text-black text-sm md:text-base font-medium ${bidStatusBasedClass}`,
                value: plannerName,
                valueClassName: `text-black text-sm font-medium md:text-base pl-1 ${bidStatusBasedClass}`
              }}
            />
          )}
          <GrandTotalExcludingVAT
            {...{
              bidStatusBasedClass,
              offerAmount
            }}
          />
        </div>
      </div>
      <BidCTA
        {...{
          disableSubmitCTA,
          handleSubmit,
          onSubmit,
          setShowRequestCallbackModal,
          status
        }}
      />
    </div>
  );
};

export default BidSummaryOrganism;
