import { useState } from 'react';

import { Text } from '@/components/atomic/atoms';
import { BidItemDetail, BidItemSummary } from '@/components/atomic/molecules';
import { BID_STATUS } from '@/config/bid';
import { mediaListMapperForBidItemDetailAPI } from '@/helpers/carousel';

const BidItemSummaryCard = ({ item, setOpenBidItemDetail, status }) => (
  <BidItemSummary
    {...{
      bidStatus: status,
      item,
      setOpenBidItemDetail
    }}
  />
);

const BidItemDetailCard = ({
  bidId,
  getBidDetail,
  openBidItemDetail,
  setOpenBidItemDetail,
  setShowToast,
  status,
  updateBidItem
}) => (
  <BidItemDetail
    {...{
      bidId,
      bidStatus: status,
      getBidDetail,
      openBidItemDetail,
      setOpenBidItemDetail,
      setShowToast,
      updateBidItem
    }}
  />
);

const BidItemList = ({
  bidId,
  getBidDetail,
  items = [],
  openBidItemDetail,
  setOpenBidItemDetail,
  setShowToast,
  status,
  updateBidItem
}) =>
  items.map((item) => {
    const showSummary = item.id !== openBidItemDetail?.id;
    const BidItemCard = showSummary ? BidItemSummaryCard : BidItemDetailCard;
    return (
      <BidItemCard
        key={item.id}
        {...{
          bidId,
          getBidDetail,
          item,
          openBidItemDetail,
          setOpenBidItemDetail,
          setShowToast,
          status,
          updateBidItem
        }}
      />
    );
  });

const BidItemListHeader = ({ status }) => {
  const bidStatusBasedClass =
    status === BID_STATUS.EXPIRED.value ? 'expired-bid' : '';
  return (
    <Text
      {...{
        content: 'Event Requirements',
        className: `text-base md:text-xl font-medium mx-6 md:mx-0 mb-6 ${bidStatusBasedClass}`
      }}
    />
  );
};

const BidItemListOrganism = ({
  bidId,
  getBidDetail,
  items: itemsRaw,
  setShowToast,
  status,
  updateBidItem
}) => {
  const [openBidItemDetail, setOpenBidItemDetail] = useState(null);
  const items = itemsRaw.map(mediaListMapperForBidItemDetailAPI);
  return (
    <div className='mt-6'>
      <BidItemListHeader {...{ status }} />
      <BidItemList
        {...{
          bidId,
          getBidDetail,
          items,
          openBidItemDetail,
          setOpenBidItemDetail,
          setShowToast,
          status,
          updateBidItem
        }}
      />
    </div>
  );
};

export default BidItemListOrganism;
