import _ from 'lodash';

import { ORDER_LIST_INDEX } from '@/config/common';
import { GENERIC_ALL_TAB_CATEGORY_LABEL } from '@/config/searchkit/common';

export const SORT_BY_ITEMS_ORDER_LIST = [
  {
    label: 'Event Date: Newest first',
    sortOrder: 0,
    value: ORDER_LIST_INDEX
  },
  {
    label: 'Event Date: Oldest first',
    sortOrder: 1,
    value: `${ORDER_LIST_INDEX}_event_asc`
  },
  {
    label: 'Grand Total: High to low',
    sortOrder: 2,
    value: `${ORDER_LIST_INDEX}_partnerOrderTotal_desc`
  }
]
  .sort((a, b) => a.sortOrder - b.sortOrder)
  .map((a) => _.omit(a, 'sortOrder'));

export const SEARCHABLE_ATTRIBUTES = [
  {
    esDocKey: null,
    key: GENERIC_ALL_TAB_CATEGORY_LABEL,
    label: 'All',
    sortOrder: 0
  },
  {
    esDocKey: 'eventLocation',
    key: 'EVENT_LOCATION',
    label: 'Event Location',
    sortOrder: 1
  },
  {
    esDocKey: 'orderNumber',
    key: 'ORDER_NUMBER',
    label: 'Order Number',
    sortOrder: 2
  },
  {
    esDocKey: 'productNames',
    key: 'PRODUCT_NAME',
    label: 'Product Name',
    sortOrder: 3
  }
]
  .sort((a, b) => a.sortOrder - b.sortOrder)
  .map((a) => _.omit(a, 'sortOrder'));
