import * as HttpService from 'services/http.service';
import { CONTACT_US_FORM } from 'services/url.service';

const contactUsForm = ({ data }) =>
  HttpService.postWithAuth(CONTACT_US_FORM(), data);

const contactHaflaFormAPI = ({ data, setShowToast }) => {
  contactUsForm({ data })
    .then(({ status }) => {
      setShowToast({
        show: true,
        status,
        message: status
          ? 'Message sent successfully'
          : 'Message not sent, something went wrong'
      });
    })
    .catch((error) =>
      setShowToast({ show: true, status: false, message: error.message })
    );
};

export default contactHaflaFormAPI;
