import {
  HomePageHeaderInfoWeb,
  NavigationCard,
  StatsBox
} from '@/components/atomic/molecules';

const HomePageWebOrganism = ({ partnerSummaryStats, partnerUser }) => {
  const { menuItemList, partnerTier, statsArray } = partnerSummaryStats;
  const { emailId, legalNameOfPartnerOrg, mobileNumber } = partnerUser;
  return (
    <div className='mx-20'>
      <HomePageHeaderInfoWeb
        {...{ emailId, legalNameOfPartnerOrg, mobileNumber, partnerTier }}
      />
      <div className='flex gap-4 mt-6'>
        <div className='flex flex-col gap-4'>
          {statsArray.map(
            (
              { count, countLabel, heading, iconUrl, value, valueLabel },
              index
            ) => (
              <StatsBox
                key={index}
                {...{
                  count,
                  countLabel,
                  heading,
                  iconUrl,
                  value,
                  valueLabel
                }}
              />
            )
          )}
        </div>
        <div className='flex w-full  mb-10'>
          <div className='flex flex-col flex-percentage gap-5 bg-white'>
            {menuItemList.map((menuItem, index) => (
              <NavigationCard
                key={index}
                {...menuItem}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageWebOrganism;
