import Image from 'next/image';
import Link from 'next/link';

import {
  Button,
  IconWithLabel,
  Text,
  TextKeyValuePair
} from '@/components/atomic/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';
import { PARTNER_TIER } from '@/services/home.service';
import { PAGE_NAME, getPageURL } from '@/services/partnerPortal.service';

// TODO: Viresh - HomePageHeaderInfoWeb and HomePageHeaderInfoMobile identically same with different classes, rectify common code and create a atom then use in both places.

const HomePageHeaderInfoWeb = ({
  emailId,
  legalNameOfPartnerOrg,
  mobileNumber,
  partnerTier
}) => {
  const iconwithLabelData = [
    {
      containerClass: 'flex gap-2 mt-3 md:mt-5',
      imgAlt: 'phone',
      imgSrc: 'call-white.svg',
      value: mobileNumber,
      valueStyle: 'text-sm font-medium text-white self-center'
    },
    {
      containerClass: 'flex gap-2 mt-3 mb-4 md:mb-0',
      imgAlt: 'email',
      imgSrc: 'email-icon.svg',
      value: emailId,
      valueStyle: 'text-sm font-medium text-white self-center'
    }
  ];

  const {
    icon,
    label,
    starIcon,
    valueStyle: valueClassName
  } = PARTNER_TIER[partnerTier];

  return (
    <div className='bg-brand-gradient rounded-2xl h-72 md:h-48 mt-6'>
      <div className='flex flex-col pt-8 px-10'>
        <div className='flex justify-between'>
          <div className='flex gap-4'>
            <Image
              alt={`${icon} -icon`}
              height={0}
              src={`${staticMediaStoreBaseURL}/icons/${icon}`}
              style={{ width: 60, height: 82 }}
              width={0}
            />
            <div className='self-end border-r-2 pr-10'>
              <TextKeyValuePair
                {...{
                  label: `Hi ${legalNameOfPartnerOrg}`,
                  labelClass:
                    'text-xl font-semibold text-white truncate max-w-36 md:max-w-100',
                  value: 'Welcome to Hafla 🎉',
                  valueClassName: 'text-sm font-medium text-white'
                }}
              />
            </div>
            <div className='self-end pl-10'>
              {iconwithLabelData.map(
                (
                  { containerClass, imgAlt, imgSrc, value, valueStyle },
                  index
                ) => (
                  <IconWithLabel
                    key={index}
                    {...{ containerClass, imgAlt, imgSrc, value, valueStyle }}
                  />
                )
              )}
            </div>
          </div>
          <Link href={getPageURL({ pageName: PAGE_NAME.PROFILE.label })}>
            <span className='flex items-center gap-1 border border-white py-2 rounded-full cursor-pointer px-4 md:px-5 shadow-card'>
              <Image
                alt='eye'
                height={0}
                src={`${staticMediaStoreBaseURL}/icons/view-icon.svg`}
                style={{ width: 16, height: 16 }}
                width={0}
              />
              <Text
                {...{
                  className: 'text-sm font-medium text-white',
                  content: 'Profile'
                }}
              />
            </span>
          </Link>
        </div>
        <Button
          {...{
            alt: 'star',
            children: `${label} partner`,
            className: `flex gap-2 bg-black self-baseline px-4 py-1 rounded-full mt-4 text-sm font-medium ${valueClassName} self-center`,
            iconHeight: 18,
            iconPosition: 'Left',
            iconUrl: `${staticMediaStoreBaseURL}/icons/${starIcon}`,
            iconWidth: 18,
            width: 'w-auto'
          }}
        />
      </div>
    </div>
  );
};

export default HomePageHeaderInfoWeb;
