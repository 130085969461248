import * as HttpService from '@/services/http.service';
import {
  GUEST_CONTACT_US,
  SEND_OTP,
  VERIFY_OTP_WITH_MOBILE
} from '@/services/url.service';

export const verifyOTPWithMobileNumber = ({ mobileNumber, otp }) =>
  HttpService.postWithoutAuth(VERIFY_OTP_WITH_MOBILE, { mobileNumber, otp });

export const partnerUserAuthentication = ({ mobileNumber }) =>
  HttpService.postWithoutAuth(SEND_OTP, { mobileNumber });

export const guestContactUs = (data) =>
  HttpService.postWithoutAuth(GUEST_CONTACT_US, data);

export const LOGIN_PAGE_JSX_MAP = {
  LOGIN_JSX: 'LoginJSX',
  OTP_INPUT_INTERFACE_JSX: 'OtpInputInterfaceJSX',
  CONTACT_US_MODAL_JSX: 'ContactUsModalJSX'
};

export const onClickSendOTP = async ({
  getValues,
  setActiveJSX,
  setOtp,
  setOtpError
}) => {
  const codeId = getValues('codeId');
  const phoneNumber = getValues('phoneNumber');

  const { status, message } = await partnerUserAuthentication({
    mobileNumber: `${codeId.code}${phoneNumber}`
  });
  if (status) {
    setActiveJSX(LOGIN_PAGE_JSX_MAP.OTP_INPUT_INTERFACE_JSX);
    setOtp(null);
    setOtpError(null);
  } else {
    setOtpError(message);
  }
};

export const onClickEditButton = ({ setActiveJSX, setOtp, setOtpError }) => {
  setOtpError(null);
  setOtp(null);
  setActiveJSX(LOGIN_PAGE_JSX_MAP.LOGIN_JSX);
};

export const onClickVerifyOTP = async ({
  getValues,
  login,
  otp,
  query,
  setOtpError
}) => {
  const incompleteOTP = otp.length < 4;
  setOtpError(incompleteOTP ? 'The entered OTP is incorrect' : null);
  if (incompleteOTP) return;
  const currentMobileNumber = `${getValues('codeId').code}${getValues(
    'phoneNumber'
  )}`;
  const { status, entity, message } = await verifyOTPWithMobileNumber({
    mobileNumber: currentMobileNumber,
    otp
  });
  setOtpError(status ? null : message);
  if (status) {
    login({
      userDetails: entity,
      pathName: query.redirectTo || 'home'
    });
  }
};
