import Image from 'next/image';

import { Text, TextKeyValuePair } from '@/components/atomic/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';
import { useIsMobile } from '@/lib/screenResolution';

const StatsBox = ({
  count,
  countLabel,
  heading,
  iconUrl,
  value,
  valueLabel
}) => {
  const [isMobile] = useIsMobile();
  return (
    <div className='border border-neutral bg-white rounded-xl w-52 md:w-96 h-full md:h-44 bg-stats-home p-2 md:p-4'>
      <Text
        {...{
          content: heading,
          className: 'font-medium text-sm md:text-base text-rich-black'
        }}
      />
      <TextKeyValuePair
        {...{
          className: 'flex gap-3 py-3',
          label: count,
          labelClass: 'font-semibold text-xl md:text-3xl text-brand',
          spaceTop:
            'font-medium text-sm md:text-base text-dim-gray self-center',
          value: countLabel,
          valueClassName: 'text-base md:text-lg'
        }}
      />
      <div className='flex gap-1 md:gap-4'>
        <Image
          alt='stats-icon'
          height={0}
          src={`${staticMediaStoreBaseURL}/icons/${iconUrl}`}
          style={{
            width: isMobile ? 32 : 64,
            height: isMobile ? 32 : 64
          }}
          width={0}
        />
        <div className='self-center'>
          <TextKeyValuePair
            {...{
              className: 'flex-col',
              label: value,
              labelClass: 'font-semibold text-lg text-rich-black',
              spaceTop: '',
              value: valueLabel,
              valueClassName: 'font-medium text-xs md:text-base text-rich-black'
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default StatsBox;
