import Image from 'next/image';

import { Text } from '@/components/atomic/atoms';
import { BID_STATUS } from '@/config/bid';
import { staticMediaStoreBaseURL } from '@/config/common';
import { convertToStandardDateFormat, formatTime } from '@/lib/dateAndTime';

const CartEventLocation = ({ status, userEventLocation }) => {
  const locationIcon =
    status === BID_STATUS.EXPIRED.value
      ? 'location-icon-gray.svg'
      : 'location-icon.svg';
  const bidStatusBasedClass =
    status === BID_STATUS.EXPIRED.value ? 'expired-bid' : '';
  return (
    <div className='flex gap-3'>
      <div className='w-6 md:w-8 self-center'>
        <Image
          alt='timer'
          height={0}
          src={`${staticMediaStoreBaseURL}/icons/${locationIcon}`}
          style={{ width: 26, height: 26 }}
          width={0}
        />
      </div>
      <div className='flex flex-col self-center gap-1 flex-1'>
        <Text
          {...{
            content: 'LOCATION',
            className: `text-xs md:text-sm font-medium text-silver`
          }}
        />
        <Text
          {...{
            content: userEventLocation,
            className: `text-sm md:text-base font-medium text-nero ${bidStatusBasedClass}`
          }}
        />
      </div>
    </div>
  );
};
const CartEventDate = ({ eventStartTime, status, userEventDate }) => {
  const dateIcon =
    status === BID_STATUS.EXPIRED.value
      ? 'calendar-gray-with-frame.svg'
      : 'calendar-red.svg';
  const bidStatusBasedClass =
    status === BID_STATUS.EXPIRED.value ? 'expired-bid' : '';
  return (
    <div className='flex gap-3'>
      <div className='w-6 md:w-8 self-center'>
        <Image
          alt='timer'
          height={0}
          src={`${staticMediaStoreBaseURL}/icons/${dateIcon}`}
          style={{ width: 24, height: 24 }}
          width={0}
        />
      </div>
      <div className='flex flex-col gap-1'>
        <Text
          {...{
            content: 'DATE & TIME',
            className: `text-xs md:text-sm font-medium text-silver`
          }}
        />

        <Text
          {...{
            content: `${convertToStandardDateFormat(
              userEventDate
            )}, ${formatTime(eventStartTime)}`,
            className: `text-sm md:text-base text-nero font-medium ${bidStatusBasedClass}`
          }}
        />
      </div>
    </div>
  );
};

const CartEventInfo = ({ bidTitle, cartNumber, status }) => {
  const bidStatusBasedClass =
    status === BID_STATUS.EXPIRED.value ? 'expired-bid' : '';
  return (
    <div className='flex flex-col gap-2'>
      <Text
        {...{
          content: bidTitle,
          className: `text-lg md:text-2xl font-medium ${bidStatusBasedClass}`
        }}
      />
      <div className='flex flex-row justify-between'>
        <div
          className={`bg-light-red text-brand text-xs md:text-sm font-medium rounded-2xl ${bidStatusBasedClass}`}
        >
          <Text
            {...{
              content: `Quote No: ${cartNumber}`,
              className: `px-4 py-1 ${bidStatusBasedClass}`
            }}
          />
        </div>
      </div>
    </div>
  );
};

const BidCartEventInfoMobile = ({ cartEventInfo, status }) => {
  const {
    bidTitle,
    cartNumber,
    eventStartTime,
    userEventDate,
    userEventLocation
  } = cartEventInfo;
  return (
    <div className='bg-white px-4 md:px-8 py-5 flex flex-col gap-4 rounded-2xl shadow-md mx-4 z-50'>
      <CartEventInfo
        {...{
          bidTitle,
          cartNumber,
          eventStartTime,
          status,
          userEventDate
        }}
      />
      <CartEventDate {...{ eventStartTime, status, userEventDate }} />
      <CartEventLocation {...{ status, userEventLocation }} />
    </div>
  );
};

export default BidCartEventInfoMobile;
