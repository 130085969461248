import Image from 'next/image';

import { Text } from '@/components/atomic/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';
import { useIsMobile } from '@/lib/screenResolution';

const WelcomeMessage = ({
  haflaLogo = 'hafla-gif.gif',
  isLoginPage,
  openContactUsModal
}) => {
  const [isMobile] = useIsMobile();

  const className = [
    isLoginPage ? 'bottom-[500px]' : 'bottom-[400px]',
    openContactUsModal ? 'bottom-[550px] md:bottom-[600px]' : '',
    'fixed z-10 flex text-center'
  ].join(' ');

  return (
    <div className={className}>
      <div>
        {isLoginPage && (
          <Text
            {...{
              content: 'Welcome to',
              className:
                'text-nero text-2.5xl md:text-4xl font-bold mb-4 uppercase'
            }}
          />
        )}
        <Image
          alt='hafla logo'
          className='mx-auto'
          height={0}
          priority={true}
          src={`${staticMediaStoreBaseURL}/icons/${haflaLogo}`}
          style={{
            width: isMobile ? 102 : 172,
            height: isMobile ? 50 : 90
          }}
          unoptimized={true}
          width={0}
        />
        <Text
          {...{
            content: 'Partner Portal',
            className: 'text-nero text-lg md:text-2xl mt-4 font-medium'
          }}
        />
      </div>
    </div>
  );
};

export default WelcomeMessage;
