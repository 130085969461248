import Image from 'next/image';
import Link from 'next/link';

import { Text, TextKeyValuePair } from '@/components/atomic/atoms';
import { useUIContext } from '@/components/context/ManagedUIContext';
import { staticMediaStoreBaseURL } from '@/config/common';
import { PAGE_NAME, getPageURL } from '@/services/partnerPortal.service';

const CloseNavigation = ({ setShowNavigationSideBar }) => (
  <span className='absolute -left-6 cursor-pointer'>
    <Image
      {...{
        alt: 'cross',
        className: '',
        height: 0,
        onClick: () => {
          setShowNavigationSideBar(false);
        },
        src: `${staticMediaStoreBaseURL}/icons/close-with-border-white.svg`,
        style: { width: 20, height: 20 },
        width: 0
      }}
    />
  </span>
);

const NavigationHeader = ({ legalNameOfPartnerOrg, mobileNumber }) => (
  <div className='flex gap-3 md:gap-4 pb-3 shadow-card px-4'>
    <div className='flex self-center'>
      <Image
        alt='logo'
        height={0}
        priority={true}
        src={`${staticMediaStoreBaseURL}/icons/hafla-round-icon.svg`}
        style={{ width: 48, height: 48 }}
        width={0}
      />
    </div>
    <TextKeyValuePair
      {...{
        className: 'flex-1 flex-col self-center',
        label: `Hi ${legalNameOfPartnerOrg}`,
        labelClass:
          'text-base md:text-lg font-medium text-nero truncate max-w-36 md:max-w-56 ',
        spaceTop: '',
        value: mobileNumber,
        valueClassName: 'text-sm md:text-base font-medium text-dim-gray'
      }}
    />
  </div>
);

const NavigationList = ({ navigationMenuItems }) => (
  <div className='flex flex-col gap-2 px-4 pt-3'>
    {navigationMenuItems.map(
      ({ className, href, icon, label, menuLabelClass, onClick }, index) => (
        <Link
          key={index}
          href={href}
        >
          <div
            className={`flex gap-2 py-2 cursor-pointer ${className || ''}`}
            onClick={onClick}
          >
            <div className='flex self-center'>
              <Image
                alt='icon'
                height={0}
                src={`${staticMediaStoreBaseURL}/icons/${icon}`}
                style={{ width: 20, height: 20 }}
                width={0}
              />
            </div>
            <Text
              {...{
                content: label,
                className: `${
                  menuLabelClass || 'text-sm'
                } md:text-base text-black font-medium flex self-center`
              }}
            />
          </div>
        </Link>
      )
    )}
  </div>
);

const NavigationSideBar = ({
  setShowNavigationSideBar,
  legalNameOfPartnerOrg,
  mobileNumber
}) => {
  const { logout } = useUIContext();
  const navigationMenuItems = [
    {
      href: getPageURL({ pageName: PAGE_NAME.HOME.label }),
      icon: 'home-icon.svg',
      label: 'Home'
    },
    {
      href: getPageURL({ pageName: PAGE_NAME.ORDER_LIST.label }),
      icon: 'cart-red.svg',
      label: 'Orders'
    },
    {
      href: getPageURL({ pageName: PAGE_NAME.BID_LIST.label }),
      icon: 'quote.svg',
      label: 'Quotes'
    },
    {
      href: getPageURL({
        pageName: PAGE_NAME.ORDER_DELIVERY_AND_PICKUPS.label
      }),
      icon: 'delivery-pickup-red.svg',
      label: 'Deliveries & Pickups'
    },
    {
      href: getPageURL({ pageName: PAGE_NAME.PROFILE.label }),
      icon: 'user-red.svg',
      label: 'Profile'
    },
    {
      href: getPageURL({ pageName: PAGE_NAME.CONTACT_US.label }),
      icon: 'phone-red-icon.svg',
      label: 'Contact us'
    },
    {
      className: 'fixed bottom-2',
      href: getPageURL({ pageName: PAGE_NAME.LOGIN.label }),
      icon: 'logout.svg',
      label: 'Logout',
      menuLabelClass: 'text-silver',
      onClick: () => logout()
    }
  ];

  return (
    <div>
      <div className='overlayer z-40 fixed top-0 min-h-screen w-full bg-nero opacity-90'></div>
      <div className='bg-white fixed z-50 right-0 top-0 min-h-screen w-60 md:w-80 py-3'>
        <CloseNavigation {...{ setShowNavigationSideBar }} />
        <NavigationHeader {...{ legalNameOfPartnerOrg, mobileNumber }} />
        <NavigationList {...{ navigationMenuItems }} />
      </div>
    </div>
  );
};

export default NavigationSideBar;
