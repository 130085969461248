import _ from 'lodash';
import { Editor, Node, Path, Range, Transforms } from 'slate';

const SLATE_SERIALIZER_DELIMITER = '\n';

export const getSerializedRichText = (value) => {
  try {
    const nodes = Array.isArray(value) ? value : value?.inclusions;

    if (_.isEmpty(nodes)) {
      return false;
    }

    if (Array.isArray(nodes)) {
      return nodes
        .map((node) => Node.string(node))
        .join(SLATE_SERIALIZER_DELIMITER);
    }

    return Node.string(nodes);
  } catch (error) {
    return false;
  }
};

export const createLinkNode = (href, text) => ({
  children: [{ text }],
  href,
  type: 'link'
});

export const removeLink = (editor) => {
  Transforms.unwrapNodes(editor, {
    match: (node) =>
      !Editor.isEditor(node) && Element.isElement(node) && node.type === 'link'
  });
};

export const insertLink = (editor, url) => {
  if (!url) return;

  const { selection } = editor;
  const link = createLinkNode(url, 'Link');
  if (selection) {
    const [parent, parentPath] = Editor.parent(editor, selection.focus.path);
    if (parent.type === 'link') {
      removeLink(editor);
    }

    if (editor.isVoid(parent)) {
      Transforms.insertNodes(
        editor,
        { type: 'paragraph', children: [link] },
        {
          at: Path.next(parentPath),
          select: true
        }
      );
    } else if (Range.isCollapsed(selection)) {
      Transforms.insertNodes(editor, link, { select: true });
    } else {
      Transforms.wrapNodes(editor, link, { split: true });
    }
  } else {
    Transforms.insertNodes(editor, { type: 'paragraph', children: [link] });
  }
};
