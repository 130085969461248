import { useEffect, useState } from 'react';
import { useCurrentRefinements, useNumericMenu } from 'react-instantsearch';
import { createInstantSearchRouterNext } from 'react-instantsearch-router-nextjs';

import { DateRangeFilterWithDatePicker } from '@/components/atomic/atoms';
import {
  getMenuFromCurrentURL,
  shouldShowEventDateRadio
} from '@/helpers/searchkit';
import { getNativeDateObject } from '@/lib/dateAndTime';
import {
  LOCAL_STORAGE_KEYS,
  getLocalStorageValue,
  removeLocalStorageValue,
  setLocalStorageValue
} from '@/services/localStorage.service';

const DEFAULT_REFINEMENT = encodeURI('{}');

const handleRadioChange = ({
  event,
  item,
  refine,
  setEndDate,
  setSelectedDateKey,
  setShowCustomRange,
  setStartDate
}) => {
  const { value } = event.target;
  setStartDate();
  setEndDate();
  setSelectedDateKey(value);
  setShowCustomRange(value === 'customRange');
  if (value !== 'customRange') {
    setLocalStorageValue({
      key: LOCAL_STORAGE_KEYS.DATE_PICKER_VALUE,
      value: item.value
    });
    return refine(item.value);
  }
  setLocalStorageValue({
    key: LOCAL_STORAGE_KEYS.DATE_PICKER_VALUE,
    value: 'customRange'
  });
  return refine(DEFAULT_REFINEMENT);
};

const RadioInputWithLabel = ({
  checked,
  labelName,
  name,
  onChange,
  showRadio,
  value
}) =>
  showRadio && (
    <li className='relative block searchkit-filter-menu-item cursor-pointer'>
      <label className='cursor-pointer block'>
        <input
          {...{
            checked,
            className:
              'checked:content-searchkit-filter-menu-item-checked searchkit-filter-menu-item-checked searchkit-radio',
            name,
            onChange,
            type: 'radio',
            value
          }}
        />
        <span className='before:bg-searchkit-filter-menu-item empty-radio-span inline-block w-5 h-5'></span>
        <span className='text-sm text-dim-gray font-medium pl-2 self-center'>
          {labelName}
        </span>
      </label>
    </li>
  );

const NumericFilterWithDatePicker = (props) => {
  const { items, refine } = useNumericMenu(props);
  const { clearDateFilter, menuAttribute, index } = props;

  const [selectedDateKey, setSelectedDateKey] = useState(DEFAULT_REFINEMENT);
  const [showCustomRange, setShowCustomRange] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedTab, setSelectedTab] = useState(null);

  const instantSearchRouter = createInstantSearchRouterNext({
    routerOptions: {
      cleanUrlOnDispose: false
    }
  });

  const { items: currentRefinementsItems } = useCurrentRefinements();

  const isDateFilterApplied = currentRefinementsItems.find(
    ({ attribute }) => attribute === 'eventDateInSeconds'
  );

  const getDateFromLocalStorage = ({ dateKey }) => {
    const dateRange = JSON.parse(
      getLocalStorageValue({ key: LOCAL_STORAGE_KEYS.DATE_RANGE })
    );
    const dateObjectParameters = {
      dateString: dateRange[dateKey]
    };
    return dateRange[dateKey]
      ? getNativeDateObject(dateObjectParameters)
      : null;
  };

  useEffect(() => {
    setStartDate();
    setEndDate();
    setShowCustomRange(false);
    setSelectedDateKey(DEFAULT_REFINEMENT);
  }, [clearDateFilter]);

  useEffect(() => {
    if (!isDateFilterApplied) {
      setStartDate();
      setEndDate();
      setShowCustomRange(false);
      setSelectedDateKey(DEFAULT_REFINEMENT);
      removeLocalStorageValue({
        keys: [
          LOCAL_STORAGE_KEYS.DATE_PICKER_VALUE,
          LOCAL_STORAGE_KEYS.DATE_RANGE
        ]
      });
    } else if (
      getLocalStorageValue({ key: LOCAL_STORAGE_KEYS.DATE_PICKER_VALUE }) ===
      'customRange'
    ) {
      setStartDate(getDateFromLocalStorage({ dateKey: 'startDate' }));
      setEndDate(getDateFromLocalStorage({ dateKey: 'endDate' }));
      setShowCustomRange(true);
      setSelectedDateKey('customRange');
    }
  }, []);

  useEffect(() => {
    if (menuAttribute) {
      getMenuFromCurrentURL({
        attribute: menuAttribute,
        index,
        instantSearchRouter,
        setSelectedTab
      });
    }
    setSelectedDateKey(
      getLocalStorageValue({ key: LOCAL_STORAGE_KEYS.DATE_PICKER_VALUE }) ||
        DEFAULT_REFINEMENT
    );
  }, [window.location.href]);

  return (
    <>
      <ul className='flex flex-col gap-4'>
        {items.map((item) => (
          <RadioInputWithLabel
            key={item.value}
            {...{
              checked: selectedDateKey === item.value,
              labelName: item.label,
              name: item.attribute,
              onChange: (event) => {
                event.preventDefault();
                handleRadioChange({
                  event,
                  item,
                  refine,
                  setEndDate,
                  setSelectedDateKey,
                  setShowCustomRange,
                  setStartDate
                });
              },
              showRadio: shouldShowEventDateRadio({
                selectedTab,
                label: item.label
              }),
              value: item.value
            }}
          />
        ))}
        <RadioInputWithLabel
          key='customRange'
          {...{
            checked: selectedDateKey === 'customRange',
            labelName: 'Custom Range',
            name: 'customRange',
            onChange: (event) => {
              handleRadioChange({
                event,
                item: null,
                refine,
                setEndDate,
                setSelectedDateKey,
                setShowCustomRange,
                setStartDate
              });
            },
            showRadio: true,
            value: 'customRange'
          }}
        />
      </ul>
      {showCustomRange && (
        <DateRangeFilterWithDatePicker
          {...{
            attribute: 'eventDateInSeconds',
            endDate,
            setEndDate,
            setStartDate,
            showCustomRange,
            startDate
          }}
        />
      )}
    </>
  );
};

export default NumericFilterWithDatePicker;
