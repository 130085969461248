import { PageHeader, Text } from '@/components/atomic/atoms';

const Heading = ({ title }) => (
  <Text
    {...{
      content: title,
      className: 'text-sm md:text-xl font-medium text-nero'
    }}
  />
);

const TermsAndConditions = () => (
  <div>
    <PageHeader
      {...{
        title: 'Terms and Conditions',
        className: 'mt-3 md:mt-5',
        height: 'h-40',
        rounded: 'rounded-b-4xl md:rounded-2xl md:mx-20 md:my-8'
      }}
    />
    <div className='-mt-20 md:-mt-24 bg-white shadow-card p-4 md:p-10 md:w-1060 flex mx-6 md:mx-auto rounded-lg'>
      <div className='font-light text-black md:text-nero'>
        <Heading {...{ title: 'TERMS & ACCEPTANCE OF TERMS' }} />
        <ul className='list-disc pl-6 disc-size mt-4'>
          <li>
            <Text
              {...{
                content: `Welcome to the Hafla Supply Partner Portal. These are the terms and conditions (Terms) which govern your use of the Supply Partner Portal. You will be deemed to have agreed to be bound by these Terms when you use, access or browse the Supply Partner Portal.`,
                className: 'text-xs md:text-base'
              }}
            />
          </li>
          <li className='py-4'>
            <Text
              {...{
                content: `We may change these Terms from time to time. Please check these Terms regularly as any changes are effective immediately upon posting to the Site. Your continued use of the Site after posting will be deemed acceptance of the changes.`,
                className: 'text-xs md:text-base'
              }}
            />
          </li>
        </ul>
        <Heading {...{ title: 'RIGHT TO MARKET & ONLINE LISTING' }} />
        <ul className='list-disc pl-6 disc-size mt-4'>
          <li>
            <Text
              {...{
                content: `The Supplier hereby grants Hafla the right to market its inventory - online and offline. This would include the right for advertising, promotion, pricing, managing inventory allocation, and for receiving reservations through all booking channels including but not limited to its Website/App/Platform, offline agents, and corporate clients.`,
                className: 'text-xs md:text-base'
              }}
            />
          </li>
          <li className='py-4'>
            <Text
              {...{
                content: `The Supplier grants Hafla a non-exclusive right to use the Supplier’s trade name, trademarks and logos for marketing purposes.`,
                className: 'text-xs md:text-base'
              }}
            />
          </li>
          <li className='py-4'>
            <Text
              {...{
                content: `Hafla reserves the right to govern the appearance, content, design, functionality and all other aspects of the site and the services (including the right to re-design, modify, remove and alter the content, appearance, design, functionality, and other aspects of the site and the service and any component, portion or feature thereof, from time to time), and to delay or suspend listing of, or to refuse to list, or to de-list, or to require you not to list, any or all products in our sole discretion.`,
                className: 'text-xs md:text-base'
              }}
            />
          </li>
        </ul>
        <Heading {...{ title: 'HAFLA SUPPLIER CODE' }} />
        <ul className='list-disc pl-6 disc-size mt-4'>
          <li>
            <Text
              {...{
                content: `The Supplier understands Hafla intends to maintain a high level of customer experience standards and the Supplier shall ensure that it will provide high quality products and services, thereby ensuring an enjoyable user experience.`,
                className: 'text-xs md:text-base'
              }}
            />
          </li>
          <li className='py-4'>
            <Text
              {...{
                content: `The Supplier confirms that the Products supplied would be 100% genuine, have no defect, expiry or damage of any kind. In case of defects the supplier will provide a replacement or reimburse “Hafla” for its cost of finding replacements.`,
                className: 'text-xs md:text-base'
              }}
            />
          </li>
          <li className='py-4'>
            <Text
              {...{
                content: `The Supplier understands that once an order is confirmed by the supplier, it is their obligation to provide the products and supplies agreed at the agreed time and location.`,
                className: 'text-xs md:text-base'
              }}
            />
          </li>
          <li className='py-4'>
            <Text
              {...{
                content: `The Supplier understands that client requirements are fluid and might change at the last minute and hence agrees to accommodate all reasonable changes requested by the client.`,
                className: 'text-xs md:text-base'
              }}
            />
          </li>
          <li className='py-4'>
            <Text
              {...{
                content: `Hafla shall have the right to evaluate the Supplier’s delivery fulfilment rating anytime throughout the term of this Agreement. Such fulfilment rating shall be determined based on percentages of Products & Services returned or not performed up to the mark out of the total orders, percentage of claims or complaints, percentage of negative feedback by Website Customers or any other criteria as Hafla may deem fit.`,
                className: 'text-xs md:text-base'
              }}
            />
          </li>
          <li className='py-4'>
            <Text
              {...{
                content: `In cases of deficient quality in delivery of product/services, Hafla reserves the right to provide a reasonable discount to customers and pass on the same to the Suppliers, including but not limited to`,
                className: 'text-xs md:text-base'
              }}
            />

            <ol className='text-xs md:text-base'>
              <li>
                <Text
                  {...{
                    content:
                      'a. Late Delivery (beyond 60 minutes of agreed time)'
                  }}
                />
                <Text
                  {...{
                    content: 'b. Damages beyond reasonable wear and tear'
                  }}
                />
                <Text
                  {...{
                    content: 'c. Dirty or unclean products'
                  }}
                />
                <Text
                  {...{
                    content: 'd. Different product than the one agreed upon'
                  }}
                />
              </li>
            </ol>
          </li>
          <li className='py-4'>
            <Text
              {...{
                content: `The Supplier shall ensure high standards of delivery experience. The Supplier’s delivery agents shall be well groomed and presentable. The agents shall wear “Hafla” branded uniforms if provided and in any case should not wear Supplier proprietary branded uniforms.`,
                className: 'text-xs md:text-base'
              }}
            />
          </li>
          <li className='py-4'>
            <Text
              {...{
                content: `The Supplier shall never directly engage with customers for pricing for existing or future orders and shall always keep the communication through “Hafla”.`,
                className: 'text-xs md:text-base'
              }}
            />
          </li>
          <li className='py-4'>
            <Text
              {...{
                content: `The suppliers shall not provide a competing quote to the customer and shall never act against Hafla’s interest.`,
                className: 'text-xs md:text-base'
              }}
            />
          </li>
        </ul>
        <Heading {...{ title: 'INDEMNITY AND LIMITATION OF LIABILITY' }} />
        <ul className='list-disc pl-6 disc-size mt-4'>
          <li className='mb-4'>
            <Text
              {...{
                content: `The Supplier shall defend, indemnify and hold Hafla harmless from loss arising from injury to persons or property caused by the fault or negligence of the Supplier or the use or purchase of any Product.`,
                className: 'text-xs md:text-base'
              }}
            />
          </li>
        </ul>
        <Heading {...{ title: 'CONFIDENTIALITY' }} />
        <ul className='list-disc pl-6 disc-size mt-4'>
          <li className='mb-4'>
            <Text
              {...{
                content: `The Parties shall maintain the confidentiality of non-public, confidential, secret, proprietary material and information made known as a consequence of entering into this Agreement, and shall not release, disclose, use, make available or copy any such Confidential Information without the other Party’s prior written consent unless required to be disclosed by a government authority or by law.`,
                className: 'text-xs md:text-base'
              }}
            />
          </li>
        </ul>
        <Heading {...{ title: 'GOVERNING LAW & DISPUTE RESOLUTION' }} />
        <ul className='list-disc pl-6 disc-size mt-4'>
          <li className='mb-4'>
            <Text
              {...{
                content: `These Terms shall be governed and interpreted in accordance with the federal laws of the United Arab Emirates. Any dispute arising out or in connection with this Agreement shall be settled by the competent courts in Dubai, UAE.`,
                className: 'text-xs md:text-base'
              }}
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
);

export default TermsAndConditions;
