import Image from 'next/image';
import { useRouter } from 'next/router';
import { Highlight } from 'react-instantsearch';

import {
  CustomAnchor,
  Text,
  TextKeyValuePair
} from '@/components/atomic/atoms';
import { BID_STATUS } from '@/config/bid';
import { staticMediaStoreBaseURL } from '@/config/common';
import { TAB_CATEGORY_CONFIG_LIST } from '@/config/searchkit/bidList';
import { convertToStandardDateFormat, formatTime } from '@/lib/dateAndTime';
import { useIsMobile } from '@/lib/screenResolution';
import { PAGE_NAME, getPageURL } from '@/services/partnerPortal.service';

const IconTextPair = ({
  imageProps: { alt, iconWidth, iconHeight, src },
  content
}) => (
  <div className='flex gap-3'>
    <Image
      {...{
        alt,
        height: 0,
        src,
        style: { width: iconWidth, height: iconHeight },
        width: 0
      }}
    />
    <div className='flex flex-1'>
      <Text
        {...{
          content,
          className: `text-sm md:text-base font-medium max-w-64 md:max-w-128 truncate text-dim-gray self-center`
        }}
      />
    </div>
  </div>
);

const EventLocation = ({ bidListTabCategory, isMobile, hit }) => (
  <IconTextPair
    {...{
      bidListTabCategory,
      content: (
        <Highlight
          {...{
            attribute: 'eventLocation',
            hit
          }}
        />
      ),
      imageProps: {
        alt: 'location',
        iconHeight: isMobile ? 20 : 24,
        iconWidth: isMobile ? 20 : 24,
        src: `${staticMediaStoreBaseURL}/icons/${
          bidListTabCategory === BID_STATUS.EXPIRED.value
            ? 'location-icon-gray.svg'
            : 'location-icon-with-frame.svg'
        }`
      }
    }}
  />
);

const EventDate = ({
  bidListTabCategory,
  eventStartTime,
  isMobile,
  userEventDate
}) => (
  <IconTextPair
    {...{
      bidListTabCategory,
      content: `${convertToStandardDateFormat(userEventDate)} - ${formatTime(
        eventStartTime
      )}`,
      imageProps: {
        alt: 'timer',
        iconHeight: isMobile ? 20 : 24,
        iconWidth: isMobile ? 20 : 24,
        src: `${staticMediaStoreBaseURL}/icons/${
          bidListTabCategory === BID_STATUS.EXPIRED.value
            ? 'calendar-gray.svg'
            : 'calendar-red-with-frame.svg'
        }`
      }
    }}
  />
);

const EventInfo = ({
  bidListTabCategory,
  bidTitle,
  className,
  content,
  hit,
  isMobile
}) => (
  <div className='flex flex-col gap-2'>
    <TextKeyValuePair
      {...{
        className: 'flex justify-between relative',
        label: bidTitle,
        labelClass:
          'text-base text-black md:text-xl font-medium max-w-48 md:max-w-none truncate md:whitespace-nowrap',
        spaceTop: '',
        value: content,
        valueClassName: `${className} px-2 md:px-3 font-medium py-1 rounded-full text-xs md:text-sm`
      }}
    />
    <div className='flex flex-row justify-between items-center'>
      <TextKeyValuePair
        {...{
          className: `flex flex-row items-center gap-1 px-2 py-1 ${
            bidListTabCategory === BID_STATUS.EXPIRED.value
              ? 'bg-white text-dim-gray'
              : 'bg-light-red text-brand'
          } text-xs md:text-sm font-medium rounded-2xl`,
          label: 'Quote No:',
          labelClass: '',
          spaceTop: '',
          value: (
            <Highlight
              {...{
                attribute: 'quoteNumber',
                hit
              }}
            />
          ),
          valueClassName: ''
        }}
      />
      <div className='self-bottom relative top-6'>
        <Image
          {...{
            alt: 'right angel',
            height: 0,
            src: `${staticMediaStoreBaseURL}/icons/${
              bidListTabCategory === BID_STATUS.EXPIRED.value
                ? 'right-angle-gray-bg.svg'
                : 'right-angle-red-bg.svg'
            }`,
            style: {
              width: isMobile ? 20 : 25,
              height: isMobile ? 20 : 25
            },
            width: 0
          }}
        />
      </div>
    </div>
  </div>
);

const getBidListTabLabelConfiguration = ({ bidListTabCategory }) => {
  const { className, label } = TAB_CATEGORY_CONFIG_LIST.find(
    ({ key }) => key === bidListTabCategory
  );

  const inActiveBidLabelClass =
    'bg-white text-dim-gray text-xs md:text-sm font-medium rounded-2xl';
  return {
    content: label,
    className:
      bidListTabCategory === BID_STATUS.EXPIRED.value
        ? inActiveBidLabelClass
        : className
  };
};

const BidCard = ({ hit: { hit } }) => {
  const {
    bidListTabCategory,
    eventDate: userEventDate,
    eventStartTime,
    eventType: bidTitle,
    referenceId,
    viewBid = () => {}
  } = hit;

  const { content, className } = getBidListTabLabelConfiguration({
    bidListTabCategory
  });

  const router = useRouter();

  const [isMobile] = useIsMobile();
  return (
    <div className='w-full md:w-58 mx-auto md:ml-0'>
      <CustomAnchor
        {...{
          onClick: () => {
            router.push(
              getPageURL({
                pageName: PAGE_NAME.BID_DETAILS.label,
                pathParams: { referenceId }
              })
            );
          }
        }}
      >
        <div
          className={`${
            bidListTabCategory === BID_STATUS.EXPIRED.value
              ? 'bg-neutral'
              : 'bg-white'
          } w-full border-neutral border px-4 md:px-8 py-5 flex flex-col gap-4 rounded-2xl shadow-card mb-6 z-50 cursor-pointer`}
          onClick={viewBid}
        >
          <EventInfo
            {...{
              bidListTabCategory,
              bidTitle,
              className,
              content,
              eventStartTime,
              hit,
              isMobile,
              userEventDate
            }}
          />
          <div className='flex flex-col md:flex-row gap-4'>
            <EventDate
              {...{
                bidListTabCategory,
                eventStartTime,
                isMobile,
                userEventDate
              }}
            />
            <EventLocation {...{ bidListTabCategory, hit, isMobile }} />
          </div>
        </div>
      </CustomAnchor>
    </div>
  );
};

export default BidCard;
