import { Text } from '@/components/atomic/atoms';
import { ProfileHeaderWeb, ProfileWeb } from '@/components/atomic/molecules';
import { getProfileData } from '@/services/profile.service';

const ProfileWebOrganism = ({
  partnerDetails,
  partnerSummaryStats,
  partnerUser
}) => {
  const { emailId, legalNameOfPartnerOrg, mobileNumber } = partnerUser;
  const { partner: { address, coreCategories = [], pocName } = {} } =
    partnerDetails || {};
  const { partnerTier } = partnerSummaryStats;

  const profileData = getProfileData({
    address,
    coreCategories,
    emailId,
    mobileNumber,
    pocName
  });

  return (
    <div>
      <div className='flex flex-col bg-profile bg-no-repeat bg-top bg-contain mx-20 my-5'>
        <div className='flex-1 w-full md:w-47.5 mx-auto bg-white shadow pb-10 rounded-3xl relative top-32 md:mb-40'>
          <div className='relative'>
            <ProfileHeaderWeb {...{ legalNameOfPartnerOrg, partnerTier }} />
            <Text
              {...{
                content: 'Personal Info',
                className: 'text-lg font-medium text-rich-black px-8 py-4'
              }}
            />
            <div className='flex flex-col gap-4'>
              {Object.entries(profileData).map(([field, value], index) => (
                <ProfileWeb
                  key={index}
                  {...{ field, value }}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileWebOrganism;
