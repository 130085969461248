import Image from 'next/image';
import { useRouter } from 'next/router';

import { Text } from '@/components/atomic/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';
import { PAGE_NAME, getPageURL } from '@/services/partnerPortal.service';

const headerClass = {
  ACTIVE: 'bg-brand-gradient',
  EXPIRED: 'bg-dim-gray'
};

const BidHeader = ({ status }) => {
  const router = useRouter();
  const { legacyBidLink } = router.query;
  return (
    <div
      className={`${headerClass[status]} block md:hidden mx-auto h-44 rounded-b-4xl px-5 md:px-8 pt-3`}
    >
      <div
        className='flex justify-end pt-1'
        onClick={() => {
          if (legacyBidLink) {
            return router.push(
              getPageURL({ pageName: PAGE_NAME.BID_LIST.label })
            );
          }
          return router.back();
        }}
      >
        <Image
          alt='close bid detail'
          className='cursor-pointer'
          height={0}
          src={`${staticMediaStoreBaseURL}/icons/close-with-border-white.svg`}
          style={{ width: 18, height: 18 }}
          width={0}
        />
      </div>
      <Text
        {...{
          content: 'Request for Event proposal',
          className:
            'font-semibold text-lg md:text-2xl text-white mt-2 uppercase text-center'
        }}
      />
    </div>
  );
};

export default BidHeader;
