import { Fragment } from 'react';

import {
  ContactForm,
  HaflaContactDetails
} from '@/components/atomic/molecules';

const ContactUsWebOrganism = ({ legalNameOfPartnerOrg, setShowToast }) => (
  <Fragment>
    <div className='flex mx-20 my-5 shadow p-8 rounded-xl'>
      <HaflaContactDetails />
      <ContactForm {...{ legalNameOfPartnerOrg, setShowToast }} />
    </div>
  </Fragment>
);

export default ContactUsWebOrganism;
