import { Fragment } from 'react';

import { IconWithLabel } from '@/components/atomic/atoms';

const ProfileHeaderMobile = ({ cityId, legalNameOfPartnerOrg }) => (
  <Fragment>
    <IconWithLabel
      {...{
        containerClass: 'relative',
        imageContainerClass:
          'flex flex-col justify-center center-text border-2 border-white rounded-full',
        imgAlt: 'hafla-icon',
        imgHeight: 80,
        imgSrc: 'hafla-round-icon.svg',
        imgWidth: 80,
        value: legalNameOfPartnerOrg,
        valueStyle:
          'text-2xl md:text-3xl font-semibold text-nero text-center pt-14 md:pt-20 px-6'
      }}
    />
    {cityId && (
      <IconWithLabel
        {...{
          containerClass: 'flex justify-center gap-1 mt-2',
          imgAlt: 'location-icon',
          imgHeight: 24,
          imgSrc: 'location-icon-with-frame.svg',
          imgWidth: 24,
          value: cityId,
          valueStyle: 'text-lg font-medium text-dim-gray text-center'
        }}
      />
    )}
  </Fragment>
);

export default ProfileHeaderMobile;
