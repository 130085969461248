import { ClearRefinements } from 'react-instantsearch';

import { Text } from '@/components/atomic/atoms';

const FilterHeading = ({
  headerProps: {
    clearLabel = 'Clear',
    heading,
    headingStyle = 'text-base',
    includedAttributes,
    isClearable = true,
    onClick = () => {}
  }
}) => (
  <div className='mb-4'>
    <div className='flex justify-between'>
      <Text
        {...{
          content: heading,
          className: `${headingStyle} text-rich-black font-medium`
        }}
      />
      {isClearable && (
        <div onClick={onClick}>
          <ClearRefinements
            {...{
              classNames: {
                root: 'searchkit-clear-all-button-container',
                button: 'searchkit-clear-all-button'
              },
              includedAttributes,
              translations: {
                resetButtonText: clearLabel
              }
            }}
          />
        </div>
      )}
    </div>
  </div>
);

export default FilterHeading;
