import { useState } from 'react';

import { SingleOtpInput } from '@/components/atomic/atoms';

const OtpInputField = ({
  className,
  containerStyle,
  disabledStyle,
  errorStyle,
  focusStyle,
  getValues,
  hasErrored,
  inputStyle,
  isDisabled,
  isInputNum,
  login,
  numInputs,
  onClickVerifyOTP,
  otp,
  placeholder = '0',
  query,
  setOtp,
  setOtpError,
  shouldAutoFocus,
  value
}) => {
  const [activeInput, setActiveInput] = useState(0);

  const getOtpValue = () => (value ? value.toString().split('') : []);

  const getPlaceholderValue = () => {
    if (typeof placeholder === 'string') {
      if (placeholder.length === numInputs) {
        return placeholder;
      }

      if (placeholder.length > 0) {
        // eslint-disable-next-line no-console
        console.error(
          'Length of the placeholder should be equal to the number of inputs.'
        );
      }
    }
    return 0;
  };

  const handleOtpChange = (otpValue) => {
    const finalOtpValue = otpValue.join('');
    setOtp(finalOtpValue);
  };

  const isInputValueValid = (inputValue) => {
    const isTypeValid = isInputNum
      ? !Number.isNaN(parseInt(inputValue, 10))
      : typeof inputValue === 'string';
    return isTypeValid && inputValue.trim().length === 1;
  };

  const focusInput = (input) => {
    const nextActiveInput = Math.max(Math.min(numInputs - 1, input), 0);
    setActiveInput(nextActiveInput);
  };

  const focusNextInput = () => {
    focusInput(activeInput + 1);
  };

  const focusPrevInput = () => {
    focusInput(activeInput - 1);
  };

  const changeCodeAtFocus = ([inputValue]) => {
    const otpValue = getOtpValue();
    otpValue[activeInput] = inputValue;
    handleOtpChange(otpValue);
  };

  const handleOnPaste = (e) => {
    e.preventDefault();

    if (isDisabled) {
      return;
    }

    const otpValue = getOtpValue();
    let nextActiveInput = activeInput;

    const pastedData = e.clipboardData
      .getData('text/plain')
      .slice(0, numInputs - activeInput)
      .split('');

    for (let pos = 0; pos < numInputs; ++pos) {
      if (pos >= activeInput && pastedData.length > 0) {
        otpValue[pos] = pastedData.shift();
        nextActiveInput++;
      }
    }

    setActiveInput(nextActiveInput);
    focusInput(nextActiveInput);
    handleOtpChange(otp);
  };

  const handleOnChange = (e) => {
    const { value: inputValue } = e.target;

    if (isInputValueValid(inputValue)) {
      changeCodeAtFocus(inputValue);
    }
  };

  const handleOnKeyDown = (e) => {
    if (e.keyCode === 8 || e.key === 'Backspace') {
      e.preventDefault();
      changeCodeAtFocus('');
      focusPrevInput();
    } else if (e.keyCode === 46 || e.key === 'Delete') {
      e.preventDefault();
      changeCodeAtFocus('');
    } else if (e.keyCode === 37 || e.key === 'ArrowLeft') {
      e.preventDefault();
      focusPrevInput();
    } else if (e.keyCode === 39 || e.key === 'ArrowRight') {
      e.preventDefault();
      focusNextInput();
    } else if (
      e.keyCode === 32 ||
      e.key === ' ' ||
      e.key === 'Spacebar' ||
      e.key === 'Space'
    ) {
      e.preventDefault();
    } else if (e.key === 'Enter') {
      onClickVerifyOTP({
        getValues,
        login,
        otp,
        query,
        setOtpError
      });
    }
  };

  const handleOnOtpInput = (e) => {
    if (isInputValueValid(e.target.value)) {
      focusNextInput();
    } else if (!isInputNum) {
      const { nativeEvent } = e;

      if (
        nativeEvent.data === null &&
        nativeEvent.inputType === 'deleteContentBackward'
      ) {
        e.preventDefault();
        changeCodeAtFocus('');
        focusPrevInput();
      }
    }
  };

  const OTPInputs = () => {
    const otpValue = getOtpValue();
    const placeholderValues = getPlaceholderValue();

    const inputElements = [];

    for (let i = 0; i < numInputs; i++) {
      inputElements.push(
        <SingleOtpInput
          key={i}
          {...{
            className,
            disabledStyle,
            errorStyle,
            focus: activeInput === i,
            focusStyle,
            hasErrored,
            index: i,
            inputStyle,
            isDisabled,
            isInputNum,
            isLastChild: i === numInputs - 1,
            onBlur: () => setActiveInput(-1),
            onChange: handleOnChange,
            onFocus: (e) => {
              setActiveInput(i);
              e.target.select();
            },
            onInput: handleOnOtpInput,
            onKeyDown: handleOnKeyDown,
            onPaste: handleOnPaste,
            placeholder: placeholderValues && placeholderValues[i],
            shouldAutoFocus,
            value: otpValue && otpValue[i]
          }}
        />
      );
    }

    return inputElements;
  };

  return (
    <div
      className='flex gap-2'
      style={containerStyle}
    >
      <OTPInputs />
    </div>
  );
};

export default OtpInputField;
