import Image from 'next/image';
import { useState } from 'react';

import { Text } from '@/components/atomic/atoms';
import { BID_REVISION_DELTA_TAG } from '@/config/bid';
import { staticMediaStoreBaseURL } from '@/config/common';
import { useIsMobile } from '@/lib/screenResolution';

const BidRevisionHeader = ({
  showBidRevisionSummary,
  setShowBidRevisionSummary
}) => {
  const [isMobile] = useIsMobile();
  return (
    <div className='flex gap-2 items-center'>
      <Text
        {...{
          content: 'Quote Revision Summary',
          className: `text-base md:text-xl self-center font-medium ml-6 md:mx-0 my-6`
        }}
      />
      <Image
        alt='show hide details'
        className={`cursor-pointer transform ${!showBidRevisionSummary ? '-rotate-90' : 'rotate-0'}`}
        height={0}
        onClick={() => setShowBidRevisionSummary(!showBidRevisionSummary)}
        src={`${staticMediaStoreBaseURL}/icons/down-arrow.svg`}
        style={{
          width: isMobile ? 12 : 15,
          height: isMobile ? 12 : 15
        }}
        width={0}
      />
    </div>
  );
};

const BidRevisionInfoRow = ({ item }) => {
  const { label, icon } = BID_REVISION_DELTA_TAG[item];
  return (
    <div className='flex gap-4'>
      <Image
        alt='revision-info'
        height={0}
        src={`${staticMediaStoreBaseURL}/${icon}`}
        style={{ width: 22, height: 22 }}
        width={0}
      />
      <Text
        {...{
          content: label,
          className: 'text-sm md:text-base font-medium'
        }}
      />
    </div>
  );
};

const BidRevisionSummary = ({ revisionDelta = [] }) => {
  const [showBidRevisionSummary, setShowBidRevisionSummary] = useState(true);
  return (
    <div className={`${showBidRevisionSummary ? 'mb-6' : ''}`}>
      <BidRevisionHeader
        {...{ showBidRevisionSummary, setShowBidRevisionSummary }}
      />
      {showBidRevisionSummary && (
        <div className='mx-6 md:mx-0'>
          <div className='shadow px-4 py-5 md:p-8 rounded-2xl flex flex-col gap-4'>
            {revisionDelta.map((item, index) => (
              <BidRevisionInfoRow
                key={index}
                {...{ item }}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default BidRevisionSummary;
