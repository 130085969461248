import Image from 'next/image';
import Link from 'next/link';

import { Text } from '@/components/atomic/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';

const NavigationCard = ({
  className,
  count,
  href,
  icon,
  label,
  shortDescription
}) => (
  <Link href={href}>
    <div className='flex gap-4 p-4 rounded-xl shadow-card border border-neutral hover:border-brand cursor-pointer md:h-44'>
      <div className='w-28 md:w-32 flex flex-col self-center relative'>
        <Image
          alt={icon}
          height={0}
          src={`${staticMediaStoreBaseURL}/icons/${icon}`}
          style={{ width: 124, height: 124 }}
          width={0}
        />
      </div>
      <div className='flex flex-1 flex-col self-center'>
        <Text
          {...{
            className: 'text-lg font-semibold text-nero',
            content: label
          }}
        />
        <Text
          {...{
            className: 'text-xs md:text-sm font-medium text-silver',
            content: shortDescription
          }}
        />
        <Text
          {...{
            className: `${className} font-medium rounded-full mr-auto px-3 py-1 mt-2 text-center text-xs`,
            content: `${count}`
          }}
        />
      </div>
      <div className='self-center ml-auto hidden md:flex'>
        <Image
          alt='right'
          height={0}
          src={`${staticMediaStoreBaseURL}/icons/right-angle-red-bg.svg`}
          style={{ width: 32, height: 32 }}
          width={0}
        />
      </div>
    </div>
  </Link>
);

export default NavigationCard;
