import { InputField, Text } from '@/components/atomic/atoms';
import { BID_STATUS, DELIVERY_MODE } from '@/config/bid';

const BidDeliveryInfo = ({
  deliveryCharge,
  deliveryMode,
  disableFields,
  register,
  setDeliveryChargeOffered,
  setDeliveryMode,
  status
}) => {
  const bidStatusBasedClass =
    status === BID_STATUS.EXPIRED.value ? 'expired-bid' : '';
  return (
    <div>
      <div className='md:flex md:gap-3'>
        <Text
          {...{
            className: `text-sm md:text-base font-medium md:self-center ${bidStatusBasedClass}`,
            content: 'Delivery Mode:'
          }}
        />

        <div className='flex gap-5 flex-row mt-2'>
          {Object.values(DELIVERY_MODE)
            .filter(({ value }) => value !== DELIVERY_MODE.NOT_APPLICABLE.value)
            .map(({ label, value }, id) => (
              <label
                key={id}
                className='radio-container pl-9 flex flex-row'
              >
                <input
                  defaultChecked={value === deliveryMode}
                  disabled={disableFields}
                  name='radio'
                  onClick={() => setDeliveryMode(value)}
                  type='radio'
                  value={value}
                />
                <span className='radiomark'></span>
                <Text
                  {...{
                    className: `text-sm md:text-base font-light ${bidStatusBasedClass}`,
                    content: label,
                    HtmlTag: 'label'
                  }}
                />
              </label>
            ))}
        </div>
      </div>
      <div>
        {deliveryMode === DELIVERY_MODE.SELF_DELIVERY.value && (
          <InputField
            {...{
              className: 'rounded-md',
              defaultValue: deliveryCharge,
              disabled: disableFields,
              label: 'Delivery Price:',
              labelClass: `mb-2 flex text-sm md:text-base font-medium md:mt-2 ${bidStatusBasedClass}`,
              onChange: (e) => setDeliveryChargeOffered(e.target.value),
              register: register('deliveryCharge'),
              type: 'number'
            }}
          />
        )}
      </div>
    </div>
  );
};

export default BidDeliveryInfo;
