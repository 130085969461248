import Image from 'next/image';

import { Text, TextKeyValuePair } from '@/components/atomic/atoms';
import { BID_STATUS } from '@/config/bid';
import { staticMediaStoreBaseURL } from '@/config/common';
import { convertToStandardDateFormat, formatTime } from '@/lib/dateAndTime';

const CartEventLocation = ({ userEventLocation }) => (
  <div className='flex gap-2 w-2/3'>
    <div className='w-6 md:w-8 self-center'>
      <Image
        alt='timer'
        height={0}
        src={`${staticMediaStoreBaseURL}/icons/location-white.svg`}
        style={{ width: 26, height: 26 }}
        width={0}
      />
    </div>
    <TextKeyValuePair
      {...{
        className: 'flex-col self-center gap-1',
        label: 'LOCATION',
        labelClass: 'text-xs md:text-sm font-medium text-white',
        spaceTop: '',
        value: userEventLocation,
        valueClassName:
          'text-sm md:text-base font-medium text-white max-w-100 truncate'
      }}
    />
  </div>
);
const CartEventDate = ({ eventStartTime, userEventDate }) => (
  <div className='flex gap-2 w-1/3'>
    <div className='w-6 md:w-8 self-center'>
      <Image
        alt='timer'
        height={0}
        src={`${staticMediaStoreBaseURL}/icons/calendar.svg`}
        style={{ width: 24, height: 24 }}
        width={0}
      />
    </div>
    <TextKeyValuePair
      {...{
        className: 'flex-col gap-1',
        label: 'DATE & TIME',
        labelClass: 'text-xs md:text-sm font-medium text-white',
        spaceTop: '',
        value: `${convertToStandardDateFormat(userEventDate)}, ${formatTime(
          eventStartTime
        )}`,
        valueClassName:
          'text-sm md:text-base text-white font-medium max-w-56 truncate'
      }}
    />
  </div>
);

const CartEventInfo = ({ bidTitle, cartNumber, status }) => {
  const bidStatusBasedClass =
    status === BID_STATUS.EXPIRED.value
      ? 'bg-base-white text-black'
      : 'bg-light-red text-brand';
  return (
    <div className='flex justify-between'>
      <Text
        {...{
          content: bidTitle,
          className: `text-lg md:text-2xl font-medium text-white max-w-130 truncate`
        }}
      />
      <div className='flex flex-row justify-between self-center'>
        <div
          className={`${bidStatusBasedClass} text-xs md:text-sm font-medium rounded-2xl`}
        >
          <Text
            {...{
              content: `Quote No: ${cartNumber}`,
              className: `px-4 py-1`
            }}
          />
        </div>
      </div>
    </div>
  );
};

const BidCartEventInfoWeb = ({ cartEventInfo, status }) => {
  const {
    bidTitle,
    cartNumber,
    eventStartTime,
    userEventDate,
    userEventLocation
  } = cartEventInfo;

  const bidStatusBasedClass =
    status === BID_STATUS.EXPIRED.value ? 'bg-dim-gray' : 'bg-brand-gradient';

  return (
    <div
      className={`${bidStatusBasedClass} px-4 md:px-6 py-5 flex flex-col gap-4 rounded-2xl shadow-md z-50`}
    >
      <CartEventInfo
        {...{
          bidTitle,
          cartNumber,
          eventStartTime,
          status,
          userEventDate
        }}
      />
      <div className='flex gap-4'>
        <CartEventDate {...{ eventStartTime, userEventDate }} />
        <CartEventLocation {...{ userEventLocation }} />
      </div>
    </div>
  );
};

export default BidCartEventInfoWeb;
