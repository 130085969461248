import { calculateTotalTimeDifferenceInSeconds } from '@/lib/dateAndTime';
import { BID_STATUS, TENDER_STATUS } from 'config/bid';
import { getSearchkitClient } from 'helpers/searchkit';
import * as HttpService from 'services/http.service';
import {
  GET_BID_DETAILS_BY_PARTNER_AND_REFERENCE_ID,
  POST_CALLBACK_REQUEST_FOR_BID,
  SEARCHKIT_BID_LIST_URL,
  SUBMIT_BID,
  UPDATE_BID_ITEM
} from 'services/url.service';

export const sendCallBackRequestForBid = async ({
  bidId,
  messageFromPartner
}) =>
  HttpService.postWithAuth(POST_CALLBACK_REQUEST_FOR_BID(bidId), {
    messageFromPartner
  });

const getBidDetailsByPartnerAndReferenceId = ({ partnerId, referenceId }) =>
  HttpService.getWithAuth(
    GET_BID_DETAILS_BY_PARTNER_AND_REFERENCE_ID({ partnerId, referenceId })
  );

export const updateBidItem = ({ bidId, bidItemId, data }) =>
  HttpService.putWithAuth(UPDATE_BID_ITEM(bidId, bidItemId), data);

export const submitBid = ({ bidId, data }) =>
  HttpService.postWithAuth(SUBMIT_BID(bidId), data);

const isUserEventExpired = ({
  status,
  tenderStatus,
  userEventDate,
  eventStartTime
}) => {
  const statusObj = {
    ACTIVE:
      calculateTotalTimeDifferenceInSeconds(userEventDate, eventStartTime) <= 0,
    EXPIRED: true
  };
  return tenderStatus === TENDER_STATUS.ACTIVE && statusObj[status];
};

const parseGetBidDetailsAPIResponse = (bidDetailsRaw = {}) => {
  const {
    status,
    tenderStatus,
    cartEventInfo: { userEventDate, eventStartTime }
  } = bidDetailsRaw;
  const isEventExpired = isUserEventExpired({
    status,
    tenderStatus,
    userEventDate,
    eventStartTime
  });
  return {
    ...bidDetailsRaw,
    isEventExpired,
    status: isEventExpired ? BID_STATUS.EXPIRED.value : status
  };
};

export const getBidDetails = async ({
  setShowToast,
  partnerId,
  referenceId
}) => {
  const {
    status: apiStatus,
    entity: bidDetailsRaw,
    message
  } = await getBidDetailsByPartnerAndReferenceId({ partnerId, referenceId });

  const bidDetails =
    apiStatus && bidDetailsRaw?.id
      ? parseGetBidDetailsAPIResponse(bidDetailsRaw)
      : false;

  !apiStatus &&
    setShowToast({
      show: true,
      status: apiStatus,
      message: message ?? message[0]
    });
  return bidDetails;
};

export const onBidRequestCallBack = async ({
  bidId,
  messageFromPartner,
  setShowRequestCallbackModal,
  setShowToast
}) => {
  const { status: apiStatus, message } = await sendCallBackRequestForBid({
    bidId,
    messageFromPartner
  });
  if (apiStatus) {
    setShowRequestCallbackModal(false);
  }
  const apiMessage = message ?? message[0];
  const toastMessage = apiStatus
    ? 'Request placed successfully!'
    : `Error! Failed to submit. Please try again. (${apiMessage})`;
  setShowToast({
    message: toastMessage,
    show: true,
    status: apiStatus
  });
};

export const bidsSearchkitClient = getSearchkitClient({
  url: SEARCHKIT_BID_LIST_URL
});
