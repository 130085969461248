const FRACTIONAL_PRECISION = 100;

const convertToUpperDenomination = ({
  formatDecimalPrecision = 2,
  returnType = 'float',
  value = 0
}) => {
  const parsedValue = Number(value);

  if (Number.isNaN(parsedValue)) {
    throw new Error(
      `unable to parse the value ${value}, parsing it results in NaN`
    );
  }

  const upperDenominationValue = parseFloat(parsedValue) / FRACTIONAL_PRECISION;

  switch (returnType.toLowerCase()) {
    case 'float':
      return parseFloat(upperDenominationValue.toFixed(formatDecimalPrecision));
    case 'string':
      return parseFloat(upperDenominationValue).toFixed(formatDecimalPrecision);
    default:
      throw new Error('unknown returnType');
  }
};

const convertToLowerDenomination = (value) =>
  Math.round(value * FRACTIONAL_PRECISION);

export const getFloatStringToTwoDecimalPlaces = (value) =>
  parseFloat(value).toFixed(2);

export const parsePriceValueForAPI = (price) =>
  Math.round(parseFloat(convertToLowerDenomination(price)).toFixed(2));

export const parseFormatPriceValueFromAPI = (value = 0) =>
  convertToUpperDenomination({
    value,
    returnType: 'string'
  });

export const parseFormatPriceNumberValueFromAPI = (value) =>
  convertToUpperDenomination({
    value,
    returnType: 'float'
  });
