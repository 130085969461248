import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';

import { Button, TextKeyValuePair } from '@/components/atomic/atoms';
import { NavigationHamburgerMenu } from '@/components/atomic/molecules';
import { staticMediaStoreBaseURL } from '@/config/common';
import { getInitiateWhatsappChatLink } from '@/services/home.service';
import { getAuth, isAuthenticated } from '@/services/identity.service';
import { PAGE_NAME, getPageURL } from '@/services/partnerPortal.service';

const haflaContactNumber = process.env.NEXT_PUBLIC_WHATSAPP_NUMBER;

const Profile = ({
  isAuthenticatedUser,
  legalNameOfPartnerOrg,
  setShowNavigationMenu,
  showNavigationMenu
}) =>
  isAuthenticatedUser ? (
    <div
      className='flex cursor-pointer relative gap-3'
      onClick={() => setShowNavigationMenu(!showNavigationMenu)}
    >
      <div>
        <Image
          alt='user-profile-icon'
          className='cursor-pointer'
          height={0}
          src={`${staticMediaStoreBaseURL}/icons/user-profile-icon.svg`}
          style={{ width: 40, height: 40 }}
          width={0}
        />
      </div>
      <TextKeyValuePair
        {...{
          className: 'flex flex-col',
          label: 'Hi',
          labelClass: 'text-xs font-light text-dim-gray',
          spaceTop: '',
          value: legalNameOfPartnerOrg,
          valueClassName:
            'text-sm font-medium text-rich-black truncate max-w-40'
        }}
      />
      <div className='self-center mt-3'>
        <Image
          alt='down-arrow-bold'
          className={`cursor-pointer transform ${
            showNavigationMenu ? 'rotate-180' : ''
          }`}
          height={0}
          src={`${staticMediaStoreBaseURL}/icons/down-arrow.svg`}
          width={0}
          style={{
            width: 14,
            height: 14
          }}
        />
      </div>
      <div className='relative'>
        {showNavigationMenu && <NavigationHamburgerMenu />}
      </div>
    </div>
  ) : (
    <Link href={getPageURL({ pageName: PAGE_NAME.LOGIN.label })}>
      <Button
        {...{
          children: 'Login',
          className:
            'bg-brand-gradient rounded-md py-2 px-6 text-white text-sm md:text-base'
        }}
      />
    </Link>
  );

const HeaderWebOrganism = ({ showCallIcon = true, showWhatsapp = true }) => {
  const { legalNameOfPartnerOrg } = getAuth() || {};

  const [showNavigationMenu, setShowNavigationMenu] = useState(false);
  const [isAuthenticatedUser, setIsAuthenticatedUser] = useState(false);

  useEffect(() => {
    setIsAuthenticatedUser(isAuthenticated());
  }, []);

  return (
    <div className='relative h-10 md:h-20'>
      <div className='bg-white shadow-card flex justify-between py-3 md:py-5 px-6 md:px-20 fixed top-0 z-10 w-full '>
        <div className='flex gap-3 md:gap-6'>
          <div className='self-center'>
            <Link href={getPageURL({ pageName: PAGE_NAME.HOME.label })}>
              <Image
                alt='logo'
                height={0}
                priority={true}
                src={`${staticMediaStoreBaseURL}/icons/hafla.svg`}
                style={{ width: 120, height: 'auto' }}
                width={0}
              />
            </Link>
          </div>
        </div>
        <div className='flex gap-4'>
          {showCallIcon && (
            <Link
              id='call-icon'
              href={`tel:${haflaContactNumber}`}
            >
              <Image
                alt='call-icon'
                className='cursor-pointer'
                height={0}
                src={`${staticMediaStoreBaseURL}/icons/call-rounded-icon.svg`}
                style={{ width: 40, height: 40 }}
                width={0}
              />
            </Link>
          )}
          {showWhatsapp && (
            <Image
              alt='whatsapp'
              className='cursor-pointer'
              height={0}
              onClick={() =>
                window.open(
                  getInitiateWhatsappChatLink({ isMobile: false }),
                  '_blank',
                  'noopener, noreferrer,nofollow'
                )
              }
              src={`${staticMediaStoreBaseURL}/icons/whatsapp-green.svg`}
              style={{ width: 40, height: 40 }}
              width={0}
            />
          )}
          <Profile
            {...{
              isAuthenticatedUser,
              legalNameOfPartnerOrg,
              setShowNavigationMenu,
              showNavigationMenu
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default HeaderWebOrganism;
