import Image from 'next/image';
import Link from 'next/link';

import { Text } from '@/components/atomic/atoms';
import { CONTACT_US, staticMediaStoreBaseURL } from '@/config/common';

const { phoneNumber, emailId, location, googlePinLocation } = CONTACT_US;

const IconWithLinkLabelValuePair = ({
  iconWithLinkLabelValuePairListProps: {
    alt,
    containerClass,
    href,
    label,
    svg,
    target,
    value,
    valueClass
  }
}) => (
  <div className={containerClass}>
    <Image
      {...{
        alt,
        height: 0,
        src: `${staticMediaStoreBaseURL}/icons/${svg}`,
        style: { height: 24, width: 24 },
        width: 0
      }}
    />
    <div className='flex flex-col'>
      <Text
        {...{
          className: 'text-lg text-nero font-medium',
          content: label
        }}
      />
      <Link
        href={href}
        target={target}
      >
        <Text
          {...{
            className: valueClass,
            content: value
          }}
        />
      </Link>
    </div>
  </div>
);

const HaflaContactDetails = () => {
  const iconWithLinkLabelValuePairList = [
    {
      alt: 'location',
      containerClass: 'flex gap-4 mt-2',
      href: googlePinLocation,
      label: 'Address',
      svg: 'location-fill-icon.svg',
      target: '_blank',
      value: location,
      valueClass: 'text-denim-blue text-lg font-medium cursor-pointer'
    },
    {
      alt: 'call',
      containerClass: 'flex gap-4',
      href: `tel:${phoneNumber}`,
      label: 'Let’s Talk',
      svg: 'call-fill.svg',
      target: '_self',
      value: phoneNumber,
      valueClass: 'text-dim-gray text-lg font-medium cursor-pointer'
    },
    {
      alt: 'email',
      containerClass: 'flex gap-4 mb-4',
      href: `mailto:${emailId}`,
      label: 'Email',
      svg: 'email-fill.svg',
      target: '_self',
      value: emailId,
      valueClass: 'text-dim-gray text-lg font-medium cursor-pointer'
    }
  ];
  return (
    <div className='flex flex-col gap-5 md:w-47.5 mx-auto mt-6 md:mt-8 pl-5 md:pl-0'>
      <Text
        {...{
          className: 'text-2xl md:text-3.5xl font-semibold mt-1',
          content: 'How to find us'
        }}
      />
      {iconWithLinkLabelValuePairList.map(
        (iconWithLinkLabelValuePairListProps, index) => (
          <IconWithLinkLabelValuePair
            key={index}
            {...{ iconWithLinkLabelValuePairListProps }}
          />
        )
      )}
    </div>
  );
};

export default HaflaContactDetails;
