import {
  ProfileHeaderMobile,
  ProfileMobile
} from '@/components/atomic/molecules';
import { getProfileData } from '@/services/profile.service';

const ProfileMobileOrganism = ({ partnerDetails, partnerUser }) => {
  const { emailId, legalNameOfPartnerOrg, mobileNumber } = partnerUser;
  const { partner: { address, cityId, coreCategories = [], pocName } = {} } =
    partnerDetails || {};

  const profileData = getProfileData({
    address,
    coreCategories,
    emailId,
    mobileNumber,
    pocName
  });

  return (
    <div>
      <div className='flex flex-col bg-brand-gradient min-h-screen overflow-hidden'>
        <div className='flex-1 w-full md:w-47.5 mx-auto bg-white rounded-t-3xl relative top-32'>
          <div className='relative'>
            <ProfileHeaderMobile {...{ cityId, legalNameOfPartnerOrg }} />
            <div className='my-4 mx-8 border border-neutral'></div>
            <div className='flex flex-col gap-4'>
              {Object.entries(profileData).map(([field, value], index) => (
                <ProfileMobile
                  key={index}
                  {...{ field, value }}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileMobileOrganism;
