import { Fragment } from 'react';

import { IconWithLabel } from '@/components/atomic/atoms';
import { PARTNER_TIER } from '@/services/home.service';

const ProfileHeaderWeb = ({ legalNameOfPartnerOrg, partnerTier }) => (
  <Fragment>
    <IconWithLabel
      {...{
        containerClass: '',
        imgAlt: `${PARTNER_TIER[partnerTier].icon}-icon`,
        imageContainerClass:
          'flex flex-col justify-center center-text rounded-full',
        imgHeight: 140,
        imgSrc: PARTNER_TIER[partnerTier].icon,
        imgWidth: 140,
        value: legalNameOfPartnerOrg,
        valueStyle:
          'text-2xl md:text-3xl font-semibold text-nero text-center pt-14 md:pt-24 px-6'
      }}
    />
    <IconWithLabel
      {...{
        containerClass:
          'flex justify-center w-40 mx-auto gap-2 bg-black px-4 py-1 rounded-full mt-4',
        imgAlt: 'star',
        imgHeight: 18,
        imgSrc: `${PARTNER_TIER[partnerTier].starIcon}`,
        imgWidth: 18,
        value: `${PARTNER_TIER[partnerTier].label} partner`,
        valueStyle: `text-sm font-medium ${PARTNER_TIER[partnerTier].valueStyle} self-center`
      }}
    />
  </Fragment>
);

export default ProfileHeaderWeb;
