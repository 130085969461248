import { LoginCTA, WelcomeMessage } from '@/components/atomic/molecules';

const SplashScreenOrganism = () => (
  <div className='bg-user-login min-h-screen flex justify-center relative'>
    <WelcomeMessage {...{ isLoginPage: false }} />
    <LoginCTA />
  </div>
);

export default SplashScreenOrganism;
