import Link from 'next/link';

import { Button, Text } from '@/components/atomic/atoms';
import getPartnersBasicInfoURL from '@/services/hostPortal.service';
import { PAGE_NAME, getPageURL } from '@/services/partnerPortal.service';

const LoginCTA = () => (
  <div className='md:w-43.5 px-5 md:px-0 fixed bottom-0 mx-auto left-0 right-0'>
    <div className='flex flex-col gap-4 font-medium'>
      <Link href={getPageURL({ pageName: PAGE_NAME.LOGIN.label })}>
        <Button
          {...{
            children: 'Login',
            className:
              'bg-brand-gradient rounded-md py-3 text-white text-sm md:text-base'
          }}
        />
      </Link>
      <Link href={getPartnersBasicInfoURL()}>
        <Button
          {...{
            children: 'Become a Partner',
            className:
              'text-sm md:text-base text-brand border-brand border rounded-md py-3'
          }}
        />
      </Link>
      <Text
        {...{
          className: 'text-brand text-center text-sm md:text-base mb-10',
          content: '3 easy steps to join the Hafla!'
        }}
      />
    </div>
  </div>
);

export default LoginCTA;
