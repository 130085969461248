import { useRouter } from 'next/router';
import { Fragment, useState } from 'react';

import {
  CloseIcon,
  OrderDetailSectionTitle,
  PageHeader
} from '@/components/atomic/atoms';
import {
  OrderDeliveryDetails,
  OrderEventInfoMobile,
  OrderEventInfoWeb,
  OrderItemCard,
  OrderItemDetail,
  OrderSummary
} from '@/components/atomic/molecules';
import { GetUiDeviceSurface } from '@/lib/screenResolution';
import { getTransformedProductMediaList } from '@/services/order.service';

const OrderDetail = ({
  deliveryDate,
  deliveryTime,
  idOfOrderItemInDetailView,
  partnerPriceWithAdditionalCharge,
  pickupDate,
  pickupTime,
  productMediaList,
  productName,
  productNotes,
  quantity,
  setIdOfOrderItemInDetailView,
  status,
  unitPartnerPrice
}) => (
  <>
    {idOfOrderItemInDetailView && (
      <OrderItemDetail
        {...{
          deliveryDate,
          deliveryTime,
          partnerPriceWithAdditionalCharge,
          pickupDate,
          pickupTime,
          productMediaList,
          productName,
          productNotes,
          quantity,
          setIdOfOrderItemInDetailView,
          status,
          unitPartnerPrice
        }}
      />
    )}
  </>
);

const OrderItemCardList = ({
  idOfOrderItemInDetailView,
  orderItems,
  setIdOfOrderItemInDetailView,
  status
}) => (
  <Fragment>
    <OrderDetailSectionTitle
      {...{ title: 'Event Requirements', setIdOfOrderItemInDetailView, status }}
    />
    {orderItems.map(
      ({
        deliveryDate,
        deliveryTime,
        id,
        orderItemMedia,
        partnerPriceWithAdditionalCharge,
        pickupDate,
        pickupTime,
        productMedia,
        productName,
        productNotes,
        quantity,
        showProductMedia,
        unitPartnerPrice
      }) => {
        const productMediaList = getTransformedProductMediaList({
          orderItemMedia,
          productMedia,
          showProductMedia
        });

        return idOfOrderItemInDetailView === id ? (
          <OrderDetail
            key={id}
            {...{
              deliveryDate,
              deliveryTime,
              idOfOrderItemInDetailView,
              partnerPriceWithAdditionalCharge,
              pickupDate,
              pickupTime,
              productMediaList,
              productName,
              productNotes,
              quantity,
              setIdOfOrderItemInDetailView,
              status,
              unitPartnerPrice
            }}
          />
        ) : (
          <OrderItemCard
            key={id}
            {...{
              deliveryDate,
              deliveryTime,
              id,
              pickupDate,
              pickupTime,
              productMediaList,
              productName,
              quantity,
              setIdOfOrderItemInDetailView,
              status
            }}
          />
        );
      }
    )}
  </Fragment>
);

const CloseOrderDetailMobile = ({ router }) => (
  <CloseIcon
    {...{
      className:
        'absolute top-2 left-100 mr-0 flex justify-end w-full -ml-3 mt-2',
      icon: 'close-with-border-white.svg',
      iconClassName: 'cursor-pointer',
      iconHeight: 18,
      iconWidth: 18,
      onClick: () => router.back()
    }}
  />
);

const CloseOrderDetailWeb = ({ router }) => (
  <div className='md:w-50 relative mx-auto bg-slate-950 flex justify-end'>
    <CloseIcon
      {...{
        className: 'hidden md:flex justify-end absolute -mr-8 top-7',
        icon: 'close-red.svg',
        iconClassName: 'cursor-pointer',
        iconHeight: 24,
        iconWidth: 24,
        onClick: () => router.back()
      }}
    />
  </div>
);

const OrderEventInfoMap = {
  mobile: OrderEventInfoMobile,
  web: OrderEventInfoWeb
};

const CloseOrderDetailMap = {
  mobile: CloseOrderDetailMobile,
  web: CloseOrderDetailWeb
};

const OrderDetailOrganism = ({ orderDetails }) => {
  const {
    eventContactName,
    eventInfo,
    exactAddress,
    googleLocationPin,
    orderItems = [],
    orderSummary = {},
    showOrderDeliveryDetails = false,
    status,
    user: { mobile } = {}
  } = orderDetails || {};
  const router = useRouter();
  const surface = GetUiDeviceSurface();
  const OrderEventInfo = OrderEventInfoMap[surface];
  const CloseOrderDetail = CloseOrderDetailMap[surface];

  const [idOfOrderItemInDetailView, setIdOfOrderItemInDetailView] =
    useState(null);

  return (
    <div>
      <div className='relative'>
        <CloseOrderDetail {...{ router }} />
        <div className='block md:hidden'>
          <PageHeader
            {...{
              className: 'mt-3',
              height: 'h-40',
              isListView: false,
              status,
              title: 'Order Details'
            }}
          />
        </div>
      </div>
      <div className='w-full md:w-50 md:mx-auto'>
        <OrderEventInfo {...{ eventInfo, exactAddress, status }} />
        {showOrderDeliveryDetails && (
          <OrderDeliveryDetails
            {...{
              eventContactName,
              exactAddress,
              googleLocationPin,
              mobile,
              status
            }}
          />
        )}
        <OrderItemCardList
          {...{
            idOfOrderItemInDetailView,
            orderItems,
            setIdOfOrderItemInDetailView,
            status
          }}
        />
        <OrderSummary {...{ orderSummary, status }} />
      </div>
    </div>
  );
};

export default OrderDetailOrganism;
