import { InputField, SelectFormInput } from 'components/atomic/atoms';
import phoneCountryCodes from 'config/phoneCountryCodes';

const PhoneCodeSelectFormInput = ({
  className,
  clearErrors,
  CustomOption,
  errors,
  getValues,
  inputFieldProps,
  isShowErrorIcon = false,
  register,
  setValue,
  width
}) => (
  <>
    <SelectFormInput
      {...{
        className,
        clearErrors,
        components: { Option: CustomOption },
        dbName: 'codeId',
        errors,
        getOptionLabel: ({ code }) => code,
        getOptionValue: ({ code, name }) => `${code}${name}`,
        getValues,
        isShowErrorIcon,
        name: 'CodeId',
        register,
        setValue,
        values: phoneCountryCodes,
        width
      }}
    />
    <InputField {...inputFieldProps} />
  </>
);

export default PhoneCodeSelectFormInput;
