import Link from 'next/link';
import { components } from 'react-select';

import { Button, ErrorMessageWithIcon, Text } from '@/components/atomic/atoms';
import PhoneCodeSelectFormInput from '@/components/general/PhoneCodeSelectFormInput';
import { staticMediaStoreBaseURL } from '@/config/common';
import phoneCountryCodes from '@/config/phoneCountryCodes';
import { LOGIN_PAGE_JSX_MAP } from '@/services/login.service';
import { PAGE_NAME, getPageURL } from '@/services/partnerPortal.service';

const PrivacyPolicy = () => (
  <div className='font-medium px-5 pt-2 text-xs md:text-sm'>
    <Text
      {...{
        content: 'By continuing, you agree to all',
        HtmlTag: 'span'
      }}
    />
    <Link href={getPageURL({ pageName: PAGE_NAME.TERMS_AND_CONDITIONS.label })}>
      <Text
        {...{
          className: 'text-denim-blue cursor-pointer',
          content: ' Terms & Conditions ',
          HtmlTag: 'span'
        }}
      />
    </Link>
    <Text
      {...{
        content: 'of Hafla.',
        HtmlTag: 'span'
      }}
    />
  </div>
);

const LoginFields = ({
  clearErrors,
  errors,
  getValues,
  onClickSendOTP,
  otpError,
  register,
  setActiveJSX,
  setOtp,
  setOtpError,
  setValue
}) => {
  const CustomOption = ({ children, data: { name, code }, ...props }) => (
    <components.Option {...props}>
      {code} {name}
    </components.Option>
  );
  const isError = Boolean(errors.phoneNumber || otpError);

  return (
    <div className='flex flex-col gap-4 font-medium pt-10 px-5'>
      <Text
        {...{
          className: 'text-base font-medium',
          content: 'Enter your phone number'
        }}
      />
      <div className='flex'>
        <PhoneCodeSelectFormInput
          {...{
            className: 'phone-code-select text-sm md:text-base',
            clearErrors,
            CustomOption,
            errors,
            getValues,
            register,
            setValue,
            phoneCountryCodes,
            width: 'w-28 md:w-40',
            inputFieldProps: {
              className:
                'phone-code-input border-0  opacity-2 rounded-r-md h-14 text-sm md:text-base pl-4',
              dbName: 'phoneNumber',
              inputGroup: 'flex flex-1',
              onChange: (e) => {
                setValue('phoneNumber', e.target.value);
                setOtpError('');
                clearErrors();
              },
              onKeyDown: (e) => {
                if (e.key === 'Enter') {
                  onClickSendOTP({
                    getValues,
                    setActiveJSX,
                    setOtp,
                    setOtpError
                  });
                }
              },
              placeholder: 'Phone No',
              register: register('phoneNumber'),
              type: 'number',
              value: getValues('phoneNumber')
            }
          }}
        />
      </div>
      {isError && (
        <ErrorMessageWithIcon
          {...{ errorMessage: errors.phoneNumber?.message || otpError }}
        />
      )}
      <Button
        {...{
          children: 'Send OTP',
          className: 'bg-white text-brand rounded-md py-3',
          disabled: isError,
          onClick: isError ? () => {} : onClickSendOTP
        }}
      />
    </div>
  );
};

const LoginInterface = ({
  clearErrors,
  errors,
  getValues,
  onClickSendOTP,
  otpError,
  register,
  setActiveJSX,
  setOtp,
  setOtpError,
  setValue
}) => (
  <div className='bg-brand-gradient rounded-t-4xl md:w-43.5 fixed bottom-0 mx-auto left-0 right-0 text-white'>
    <LoginFields
      {...{
        clearErrors,
        errors,
        getValues,
        onClickSendOTP,
        otpError,
        register,
        setActiveJSX,
        setOtp,
        setOtpError,
        setValue
      }}
    />
    <PrivacyPolicy />
    <div className='border-t border-base-white my-8 mx-6 opacity-50'></div>
    <div className='mx-6 pb-10'>
      <Button
        {...{
          children: 'Contact Us',
          className:
            'bg-transprent border border-white text-white rounded-md py-3 font-medium',
          iconPosition: 'Left',
          iconUrl: `${staticMediaStoreBaseURL}/icons/call-white.svg`,
          iconWidth: 20,
          iconHeight: 20,
          onClick: () => setActiveJSX(LOGIN_PAGE_JSX_MAP.CONTACT_US_MODAL_JSX)
        }}
      />
      <Text
        {...{
          content: 'In case you need any help!',
          className: 'text-xs md:text-sm font-medium mt-2 text-base-white'
        }}
      />
    </div>
  </div>
);

export default LoginInterface;
