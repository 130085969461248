import Image from 'next/image';
import Link from 'next/link';

import { Text } from '@/components/atomic/atoms';
import { useUIContext } from '@/components/context/ManagedUIContext';
import { staticMediaStoreBaseURL } from '@/config/common';
import { PAGE_NAME, getPageURL } from '@/services/partnerPortal.service';

const NavigationHamburgerMenu = () => {
  const { logout } = useUIContext();
  const navigationMenuItems = [
    {
      href: getPageURL({ pageName: PAGE_NAME.HOME.label }),
      icon: 'home-icon.svg',
      iconOnHover: 'home-white.svg',
      label: 'Home'
    },
    {
      href: getPageURL({ pageName: PAGE_NAME.ORDER_LIST.label }),
      icon: 'cart-red.svg',
      iconOnHover: 'order-white.svg',
      label: 'Orders'
    },
    {
      href: getPageURL({ pageName: PAGE_NAME.BID_LIST.label }),
      icon: 'quote.svg',
      iconOnHover: 'quote-white.svg',
      label: 'Quotes'
    },
    {
      href: getPageURL({
        pageName: PAGE_NAME.ORDER_DELIVERY_AND_PICKUPS.label
      }),
      icon: 'delivery-pickup-red.svg',
      iconOnHover: 'delivery-pickup-white.svg',
      label: 'Deliveries & Pickups'
    },
    {
      href: getPageURL({ pageName: PAGE_NAME.PROFILE.label }),
      icon: 'user-red.svg',
      iconOnHover: 'user-white.svg',
      label: 'Profile'
    },
    {
      href: getPageURL({ pageName: PAGE_NAME.CONTACT_US.label }),
      icon: 'phone-red-icon.svg',
      iconOnHover: 'call-white.svg',
      label: 'Contact us'
    },
    {
      href: getPageURL({ pageName: PAGE_NAME.LOGIN.label }),
      icon: 'logout-red-icon.svg',
      iconOnHover: 'logout-white.svg',
      label: 'Logout',
      onClick: () => logout()
    }
  ];

  return (
    <div className='absolute z-20 w-60 top-16 mt-1 py-2 -left-56 shadow-md bg-white rounded'>
      <ul className='flex flex-col gap-4 font-medium text-charleston-green navigation-humburger-menu rounded'>
        <li className='flex flex-col gap-2 pt-3'>
          {navigationMenuItems.map(
            (
              {
                className,
                href,
                icon,
                iconOnHover,
                label,
                menuLabelClass,
                onClick
              },
              index
            ) => (
              <Link
                key={index}
                href={href}
              >
                <div
                  className={`flex gap-2 py-2 px-4 menu-item cursor-pointer ${
                    className || ''
                  }`}
                  onClick={onClick}
                >
                  <div className='flex self-center'>
                    <Image
                      alt='icon'
                      className='red-icon'
                      height={0}
                      src={`${staticMediaStoreBaseURL}/icons/${icon}`}
                      style={{ width: 20, height: 20 }}
                      width={0}
                    />
                    <Image
                      alt='icon'
                      className='white-icon'
                      height={0}
                      src={`${staticMediaStoreBaseURL}/icons/${iconOnHover}`}
                      style={{ width: 20, height: 20 }}
                      width={0}
                    />
                  </div>
                  <Text
                    {...{
                      className: `${
                        menuLabelClass || 'text-sm'
                      } md:text-base text-black font-medium flex self-center`,
                      content: label
                    }}
                  />
                </div>
              </Link>
            )
          )}
        </li>
      </ul>
    </div>
  );
};

export default NavigationHamburgerMenu;
