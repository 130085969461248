import Image from 'next/image';
import { useState } from 'react';

import { Text } from '@/components/atomic/atoms';
import {
  BID_ITEM_AVAILABILITY_STATUS,
  BID_ITEM_STATUS,
  BID_STATUS
} from '@/config/bid';
import { staticMediaStoreBaseURL } from '@/config/common';
import { getImageURL } from '@/helpers/carousel';
import { useIsMobile } from '@/lib/screenResolution';
import { noProductImageURL } from '@/services/partnerPortal.service';

const PendingLabel = () => (
  <div className='mb-5'>
    <Text
      {...{
        className:
          'text-warning-dark bg-warning-light px-3 py-1 text-xs font-medium rounded-2xl absolute -left-14 ',
        content: BID_ITEM_STATUS.PENDING.label
      }}
    />
  </div>
);

const ImageLabelContent = ({ src, alt, className = '', isMobile }) => (
  <div className='relative left-1'>
    <Image
      {...{
        alt,
        className,
        height: 0,
        src,
        style: { height: isMobile ? 16 : 20, width: isMobile ? 16 : 20 },
        width: 0
      }}
    />
  </div>
);

const ItemAvailabilityStatusLabelComponentProps = {
  [BID_ITEM_AVAILABILITY_STATUS.AVAILABLE.value]: {
    src: `${staticMediaStoreBaseURL}/icons/green-fill-tick.svg`,
    alt: 'green'
  },
  [BID_ITEM_AVAILABILITY_STATUS.NOT_AVAILABLE.value]: {
    src: `${staticMediaStoreBaseURL}/icons/red-cross-fill-icon.svg`,
    alt: 'red'
  }
};

const getItemStatusLabelConfiguration = ({
  bidItemAvailabilityStatus,
  bidItemStatus
}) => {
  const ItemStatusLabel =
    bidItemAvailabilityStatus === null ? PendingLabel : ImageLabelContent;
  const existingItemStatusLabelProps =
    ItemAvailabilityStatusLabelComponentProps[bidItemAvailabilityStatus] || {};
  const isBidItemStatusNotPendingAndSourceAvailable =
    existingItemStatusLabelProps.src &&
    bidItemStatus !== BID_ITEM_STATUS.PENDING.value;

  return {
    ItemStatusLabel,
    itemStatusLabelProps: {
      ...existingItemStatusLabelProps,
      className: isBidItemStatusNotPendingAndSourceAvailable ? 'opacity-40' : ''
    }
  };
};

const BidItemSummaryInfo = ({
  bidStatusBasedClass,
  coverImage,
  isMobile,
  ItemStatusLabel,
  itemStatusLabelProps,
  mediaList,
  name,
  quantityRequested
}) => {
  const [imageURL, setImageURL] = useState(getImageURL(coverImage));
  return (
    <div className='flex gap-3'>
      {mediaList.length > 0 && (
        <div className='w-18 md:w-40 h-20 md:h-36 relative image-bid-summary'>
          <Image
            alt='bid item'
            className='rounded-l-xl'
            fill={true}
            onError={() => setImageURL(noProductImageURL)}
            priority={true}
            sizes='(max-width: 600px) 100vw, 50vw'
            src={imageURL}
          />
        </div>
      )}
      <div className='flex flex-col flex-1 self-center'>
        <Text
          {...{
            className: `text-sm md:text-lg font-medium truncate max-w-36 md:max-w-80 ${bidStatusBasedClass}`,
            content: name
          }}
        />
        <div className='flex gap-2 mt-1'>
          <Text
            {...{
              className: `text-xs md:text-base font-medium self-center ${bidStatusBasedClass}`,
              content: 'Quantity: '
            }}
          />
          <Text
            {...{
              className: `text-xs md:text-base font-light ${bidStatusBasedClass}`,
              content: quantityRequested
            }}
          />
        </div>
      </div>
      <div className='flex flex-col gap-3'>
        <div className='flex justify-end mt-1 relative'>
          <ItemStatusLabel {...{ ...itemStatusLabelProps, isMobile }} />
        </div>
        <div className='md:mt-5 cursor-pointer'>
          <Image
            alt='right'
            height={0}
            src={`${staticMediaStoreBaseURL}/icons/right-angle-red-bg.svg`}
            style={{
              height: isMobile ? 16 : 32,
              width: isMobile ? 16 : 32
            }}
            width={0}
          />
        </div>
      </div>
    </div>
  );
};
const BidItemSummary = ({ bidStatus, item, setOpenBidItemDetail }) => {
  const {
    availabilityStatus,
    id,
    partnerNotes,
    status,
    tenderItem,
    unitPartnerPriceOffered
  } = item;
  const [isMobile] = useIsMobile();
  const { name, quantityRequested, mediaList } = tenderItem;
  const { ItemStatusLabel, itemStatusLabelProps } =
    getItemStatusLabelConfiguration({
      bidItemStatus: status,
      bidItemAvailabilityStatus: availabilityStatus
    });
  const coverImage = mediaList[0];
  const bidStatusBasedClass =
    bidStatus === BID_STATUS.EXPIRED.value ? 'expired-bid' : '';

  return (
    <div
      className={`ml-5 mr-3  md:mx-0 pr-6 rounded-xl my-4 h-20 md:h-36 border border-neutral ${bidStatusBasedClass} bid-item-summary`}
      onClick={() =>
        setOpenBidItemDetail({
          availabilityStatus,
          id,
          partnerNotes,
          status,
          tenderItem,
          unitPartnerPriceOffered
        })
      }
    >
      <BidItemSummaryInfo
        {...{
          bidStatusBasedClass,
          coverImage,
          isMobile,
          ItemStatusLabel,
          itemStatusLabelProps,
          mediaList,
          name,
          quantityRequested
        }}
      />
    </div>
  );
};

export default BidItemSummary;
