import Image from 'next/image';

import {
  IconWithLabel,
  ImageCarousel,
  RichTextEditor,
  Text,
  TextKeyValuePair
} from '@/components/atomic/atoms';
import { ORDER_STATUS, staticMediaStoreBaseURL } from '@/config/common';
import { getSerializedRichText } from '@/helpers/editor';
import { convertToDateTimeString } from '@/lib/dateAndTime';
import { parseFormatPriceValueFromAPI } from '@/lib/numberStringUtils';
import { useIsMobile } from '@/lib/screenResolution';

const getStatusBasedOptions = ({ status }) => {
  const dictionary = { orderStatusSpecificClassName: '' };
  switch (status) {
    case ORDER_STATUS.CANCELLED.value:
      dictionary.orderStatusSpecificClassName = 'cancelled-order';
      break;
    default:
      break;
  }
  return dictionary;
};

const DateField = ({ dateField, iconURL, timeField, title }) =>
  dateField && (
    <div className='flex justify-between'>
      <IconWithLabel
        {...{
          containerClass: 'flex gap-2',
          imgAlt: 'Delivery',
          imgHeight: 24,
          imgSrc: iconURL,
          imgWidth: 24,
          value: title,
          valueStyle: ''
        }}
      />
      <Text
        {...{
          content: convertToDateTimeString({ date: dateField, time: timeField })
        }}
      />
    </div>
  );

const CloseIcon = ({ onClick }) => (
  <div
    className='flex justify-end absolute w-full z-2 -mt-2 -ml-10 md:-ml-5'
    onClick={onClick}
  >
    <Image
      alt='close'
      className='cursor-pointer top-4 absolute'
      height={0}
      src={`${staticMediaStoreBaseURL}/icons/close-gray-white-icon.svg`}
      style={{ width: 24, height: 24 }}
      width={0}
    />
  </div>
);

const OrderItemImage = ({ isMobile, productMediaList }) =>
  productMediaList?.length > 0 && (
    <div
      className={`w-full ${
        isMobile ? 'h-50' : 'h-96'
      } relative image-bid-detail`}
    >
      <ImageCarousel
        {...{
          containerStyle: `${isMobile ? 'h-50' : 'h-96'} w-full`,
          excludeImageHeightFromContainer: true,
          images: productMediaList,
          style: 'rounded-t-xl'
        }}
      />
    </div>
  );

const OrderItemTextDetail = ({
  deliveryDate,
  deliveryTime,
  partnerPriceWithAdditionalCharge,
  pickupDate,
  pickupTime,
  productName,
  productNotes,
  quantity,
  status,
  unitPartnerPrice
}) => {
  const { orderStatusSpecificClassName } = getStatusBasedOptions({ status });
  const defaultLabelValueClassName = `text-sm md:text-base font-medium ${orderStatusSpecificClassName}`;

  const itemTextDetails = [
    {
      className: 'flex justify-between',
      label: 'Quantity ',
      labelClass: defaultLabelValueClassName,
      spaceTop: 'mt-0',
      value: quantity,
      valueClassName: defaultLabelValueClassName
    },
    {
      className: 'flex justify-between',
      label: 'Unit Price (AED) ',
      labelClass: defaultLabelValueClassName,
      spaceTop: 'mt-0',
      value: parseFormatPriceValueFromAPI(unitPartnerPrice),
      valueClassName: defaultLabelValueClassName
    }
  ];

  return (
    <div>
      <div className='flex flex-col gap-4 px-4 md:px-8'>
        <Text
          {...{
            content: productName,
            className: `text-lg font-semibold mt-2 ${orderStatusSpecificClassName}`
          }}
        />
        {getSerializedRichText(productNotes) && (
          <div className='flex-col'>
            <Text
              {...{
                content: 'Note:',
                className: defaultLabelValueClassName
              }}
            />
            <RichTextEditor
              {...{
                editorStyle: `text-sm md:text-base mt-1 font-light ${orderStatusSpecificClassName}`,
                readOnly: true,
                values: productNotes
              }}
            />
          </div>
        )}
        {itemTextDetails.map((item, id) => (
          <TextKeyValuePair
            key={id}
            {...item}
          />
        ))}
        <div className='flex flex-col justify-between'>
          <TextKeyValuePair
            {...{
              className: 'flex justify-between',
              label: 'Total Price (AED)  ',
              labelClass: defaultLabelValueClassName,
              value: parseFormatPriceValueFromAPI(
                partnerPriceWithAdditionalCharge
              ),
              valueClassName: defaultLabelValueClassName
            }}
          />
          <Text
            {...{
              content: '*The above prices are excluding VAT',
              className: `text-xs mt-2 pb-2 ${orderStatusSpecificClassName}`
            }}
          />
        </div>
      </div>
      {(deliveryDate || pickupDate) && (
        <div className='flex flex-col gap-3 md:gap-6 bg-olive-black text-white text-sm md:text-base font-medium px-4 md:px-8 py-5 rounded-b-lg mt-4'>
          <DateField
            {...{
              dateField: deliveryDate,
              iconURL: 'delivery-truck.svg',
              timeField: deliveryTime,
              title: 'Delivery'
            }}
          />
          <DateField
            {...{
              dateField: pickupDate,
              iconURL: 'pickup-truck.svg',
              timeField: pickupTime,
              title: 'Pick-up'
            }}
          />
        </div>
      )}
    </div>
  );
};

const OrderItemDetail = ({
  deliveryDate,
  deliveryTime,
  partnerPriceWithAdditionalCharge,
  pickupDate,
  pickupTime,
  productMediaList,
  productName,
  productNotes,
  quantity,
  setIdOfOrderItemInDetailView,
  status,
  unitPartnerPrice
}) => {
  const [isMobile] = useIsMobile();
  return (
    <div className='relative bit-item-detail'>
      <div className='flex flex-col gap-3 mx-4 md:mx-0 shadow border border-neutral rounded-lg'>
        <CloseIcon {...{ onClick: () => setIdOfOrderItemInDetailView(null) }} />
        <OrderItemImage {...{ isMobile, productMediaList }} />
        <div>
          <OrderItemTextDetail
            {...{
              deliveryDate,
              deliveryTime,
              partnerPriceWithAdditionalCharge,
              pickupDate,
              pickupTime,
              productMediaList,
              productName,
              productNotes,
              quantity,
              status,
              unitPartnerPrice
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderItemDetail;
