import _ from 'lodash';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Highlight } from 'react-instantsearch';

import {
  CustomAnchor,
  Text,
  TextKeyValuePair
} from '@/components/atomic/atoms';
import { ORDER_STATUS, staticMediaStoreBaseURL } from '@/config/common';
import { convertToStandardDateFormat } from '@/lib/dateAndTime';
import { useIsMobile } from '@/lib/screenResolution';
import { getStatusBasedAttributes } from '@/services/order.service';
import { PAGE_NAME, getPageURL } from '@/services/partnerPortal.service';

const OrderHeader = ({ eventType, hit, orderNumberClass, status }) => (
  <div className='flex justify-between relative'>
    <div>
      <Text
        {...{
          className:
            'text-base text-black md:text-xl font-medium max-w-48 md:max-w-lg truncate md:whitespace-nowrap',
          content: eventType
        }}
      />
      {status === ORDER_STATUS.CANCELLED.value && (
        <Text
          {...{
            className: 'text-error-base text-sm font-medium flex self-center',
            content: 'Event Cancelled'
          }}
        />
      )}
    </div>
    <TextKeyValuePair
      {...{
        className: `px-2 md:px-4 py-1 text-white self-baseline flex gap-1 text-xs md:text-sm font-medium rounded-2xl ${orderNumberClass}`,
        label: 'Order No: ',
        labelClass: '',
        value: (
          <Highlight
            {...{
              attribute: 'orderNumber',
              hit
            }}
          />
        ),
        valueClassName: ''
      }}
    />
  </div>
);

const OrderDetails = ({
  dateIcon,
  eventDate,
  eventLocation,
  hit,
  isMobile,
  locationIcon,
  productNames,
  themeDecor
}) => {
  const productSummarySuffix =
    _.size(productNames) > 1 ? ` + ${_.size(productNames) - 1} more` : '';

  return (
    <div className='flex flex-col md:flex-row gap-3 md:gap-8'>
      <div className='flex gap-8'>
        <div className='flex gap-3'>
          <Image
            {...{
              alt: 'calendar',
              height: 0,
              src: `${staticMediaStoreBaseURL}/icons/${dateIcon}`,
              style: {
                height: isMobile ? 20 : 25,
                width: isMobile ? 20 : 25
              },
              width: 0
            }}
          />
          <div className='flex flex-1'>
            <Text
              {...{
                className:
                  'text-sm md:text-base font-medium text-dimGray self-center',
                content: convertToStandardDateFormat(eventDate)
              }}
            />
          </div>
        </div>
      </div>
      {eventLocation && (
        <div className='flex gap-3'>
          <Image
            {...{
              alt: 'location',
              height: 0,
              src: `${staticMediaStoreBaseURL}/icons/${locationIcon}`,
              width: 0,
              style: {
                height: isMobile ? 20 : 25,
                width: isMobile ? 20 : 25
              }
            }}
          />

          <div className='flex flex-1'>
            <Text
              {...{
                content: (
                  <Highlight
                    {...{
                      attribute: 'eventLocation',
                      hit
                    }}
                  />
                ),
                className:
                  'text-sm md:text-base font-medium max-w-64 truncate text-dimGray self-center'
              }}
            />
          </div>
        </div>
      )}
      <div className='flex gap-3'>
        <Image
          {...{
            alt: 'icon',
            height: 0,
            src: `${staticMediaStoreBaseURL}/icons/${themeDecor}`,
            width: 0,
            style: {
              height: isMobile ? 20 : 25,
              width: isMobile ? 20 : 25
            }
          }}
        />

        <div className='flex flex-1'>
          <Text
            {...{
              className:
                'text-sm md:text-base font-medium max-w-36 md:max-w-128 truncate text-dimGray self-center',
              content: (
                <Highlight
                  {...{
                    attribute: 'productNames',
                    hit
                  }}
                />
              )
            }}
          />
          {productSummarySuffix && (
            <Text
              {...{
                className:
                  'text-sm md:text-base font-medium text-dimGray self-center',
                content: productSummarySuffix
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const OrderFooterItem = ({
  orderFooterItemProps: {
    content,
    icon,
    label,
    orderNumberClass,
    rootClassName,
    value
  }
}) => (
  <div className={rootClassName}>
    <Image
      alt={icon}
      height={0}
      src={`${staticMediaStoreBaseURL}/icons/${icon}`}
      style={{ width: 28, height: 28 }}
      width={0}
    />
    <TextKeyValuePair
      {...{
        className: 'flex flex-col',
        label,
        labelClass: 'text-xs text-neutral uppercase',
        value,
        valueClassName: 'text-xs md:text-base'
      }}
    />
    {content > 0 && (
      <Text
        className={`px-2 py-1 text-white self-end flex gap-1 text-xs font-medium rounded-2xl ${orderNumberClass}`}
        content={`+${content}`}
      />
    )}
  </div>
);

const OrderFooter = ({
  deliveryDates,
  deliveryIcon,
  dividerClass,
  earliestDeliveryDateString,
  earliestPickupDateString,
  grandTotalClass,
  orderNumberClass,
  pickupDates,
  pickupIcon
}) => {
  const orderFooterItemList = [
    {
      content: _.size(deliveryDates) - 1,
      icon: deliveryIcon,
      label: 'Delivery',
      orderNumberClass,
      rootClassName: 'w-1/2 flex gap-3 items-center',
      value: earliestDeliveryDateString
    },
    {
      content: _.size(pickupDates) - 1,
      icon: pickupIcon,
      label: 'Pick-up',
      orderNumberClass,
      rootClassName: `w-1/2 ${dividerClass} pl-8 gap-3 items-center flex border-l`,
      value: earliestPickupDateString
    }
  ];
  return (
    <div
      className={`flex ${grandTotalClass} text-white font-medium rounded-b-lg md:rounded-b-xl py-2 md:py-3 px-3 md:px-8`}
    >
      {orderFooterItemList.map((orderFooterItemProps, index) => (
        <OrderFooterItem
          key={index}
          {...{ orderFooterItemProps }}
        />
      ))}
    </div>
  );
};

const OrderInfoCard = ({ hit: { hit }, pageName }) => {
  const router = useRouter();

  const {
    eventDate,
    eventLocation,
    eventType,
    orderId,
    productNames,
    deliveryDates = [],
    pickupDates = [],
    status
  } = hit;
  const [isMobile] = useIsMobile();

  const {
    dateIcon,
    deliveryIcon,
    dividerClass,
    grandTotalClass,
    locationIcon,
    orderNumberClass,
    pickupIcon,
    rightArrowIcon,
    themeDecor
  } = getStatusBasedAttributes({ status });

  const earliestDeliveryDateString =
    deliveryDates.length > 0
      ? convertToStandardDateFormat(deliveryDates.at(0))
      : 'NA';

  const earliestPickupDateString =
    _.size(pickupDates) > 0
      ? convertToStandardDateFormat(pickupDates.at(0))
      : 'NA';

  return (
    <div className='w-full md:w-58 mx-auto md:ml-0'>
      <CustomAnchor
        {...{
          onClick: () => {
            router.push(
              getPageURL({
                pageName: PAGE_NAME.ORDER_DETAILS.label,
                pathParams: { orderId },
                queryParams: { redirectingFromPageName: pageName }
              })
            );
          }
        }}
      >
        <div className='shadow-card border border-neutral mb-6 z-50 cursor-pointer rounded-lg md:rounded-xl'>
          <div className='bg-white w-full px-4 md:px-8 py-5 flex flex-col gap-3 md:gap-4 rounded-lg md:rounded-xl'>
            <div className='flex flex-col'>
              <OrderHeader
                {...{
                  eventType,
                  hit,
                  orderNumberClass,
                  status
                }}
              />
              <div className='flex justify-end relative items-start'>
                <div className='self-start absolute top-10 md:top-5'>
                  <Image
                    {...{
                      alt: 'right angel',
                      height: 0,
                      src: `${staticMediaStoreBaseURL}/icons/${rightArrowIcon}`,
                      width: 0,
                      style: {
                        width: isMobile ? 20 : 24,
                        height: isMobile ? 20 : 24
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <OrderDetails
              {...{
                dateIcon,
                eventDate,
                eventLocation,
                hit,
                isMobile,
                locationIcon,
                productNames,
                themeDecor
              }}
            />
          </div>
          <OrderFooter
            {...{
              deliveryDates,
              deliveryIcon,
              dividerClass,
              earliestDeliveryDateString,
              earliestPickupDateString,
              grandTotalClass,
              orderNumberClass,
              pickupDates,
              pickupIcon
            }}
          />
        </div>
      </CustomAnchor>
    </div>
  );
};

export default OrderInfoCard;
